import React from 'react';
import numeral from 'numeral';
import { Grid }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingTop: '15px',
      fontFamily: 'futura-pt'
    },
    raisedAmount: {
        fontSize: '1.7rem',
        marginBottom: '4px',
        fontWeight: '600',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.4rem',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem',
          }
    },
    raisedOf: {
        fontWeight: '400', fontSize: '.6em', marginRight:'20px'
    }
}));

const ProgressBar = ({ total_amount_in_cents, progress, goal, goalOn, progressBarColor }) => {
    const classes = useStyles();

    return (
    <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <div id="donately-progress-bar" className="w-full block" style={{ color: 'rgb(55, 66, 70)', fontWeight: '700', display: 'table' }}>
                    <div className="donately-progress-text" style={{ display: 'table-cell', verticalAlign: 'top', textAlign: 'center', minWidth: '120px' }}>
                        { goalOn &&
                            <div className={`donately-amount-raised-text ${classes.raisedAmount}`}>{numeral(total_amount_in_cents / 100).format('$0,0.00') }<span className={classes.raisedOf}>{' '}raised of {numeral(goal).format('$0,0') } goal</span></div>
                        }
                        { !goalOn &&
                            <div className={`donately-amount-raised-text ${classes.raisedAmount}`}>{numeral(total_amount_in_cents / 100).format('$0,0.00') }<span className={classes.raisedOf}>{' '}raised</span></div>
                        }
                    </div>
                </div>
            </Grid>
        </Grid>
        { progress &&
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <div className="donately-progress-container" data-version="1.1" style={{ display: 'block', verticalAlign: 'top', width: '100%', padding: '.2em 0px 0.5em 0em' }}>
                    <div className="donately-progress-empty" style={{ height: '25px', borderRadius: '12.5px', backgroundColor: 'rgb(238, 239, 239)' }}>
                        <div className="donately-progress-fill" style={{ height: '25px', transition: 'width 2s ease 0s', width: `${((total_amount_in_cents / 100) * 100) / goal}%`, backgroundColor: progressBarColor ? progressBarColor : 'rgb(16, 63, 161)', borderRadius: '12.5px' }}></div>
                    </div>
                    <div className="donately-progress-percentage-text" style={{display: "block", textAlign: 'left', marginLeft: ".2em", marginTop: "0.5em", fontSize: "0.9em"}}>{numeral(((total_amount_in_cents / 100) * 100) / goal).format('0.00')}% Funded</div>
                </div>
            </Grid>
        </Grid>
        }
    </div>
    );
}

export default ProgressBar;