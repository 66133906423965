import React, { useState, useEffect } from "react";
import Header from "./components/star-hero/header";
import Config from "utils/config/giving-tuesday";
import StarHero from "./components/star-hero";
import { Grid, Box } from "@material-ui/core";
import { isBrowser, isMobile } from "react-device-detect";

/* Sections */
import DonationForm from "./components/donation-form";
import GlassContainer from "./components/sections/glass-container";
import WaveContainer from "./components/sections/wave-container";
import VideoContainer from "./components/sections/video-container";
import MobiContainer from "./components/sections/mobi-container";
import WhoQuoteContainer from "./components/sections/who-quote-container";

import useStyles from "./styles";

const mainBannerDesktop =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/gt-hero-desktop-min.jpg";
const mainBannerTablet =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/gt-hero-tablet-min.jpg"
const mainBannerMobile =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/gt-hero-mobile-min.jpg"
const boysWater =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/boy-water-min.png";
const glassWater =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/gt-water-wave-top_2x-min.jpg";
const glassWaterMobile =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/water-glass-right_2x.png";
const waveGraphic =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/Textbox_Wave_Graphic-01_2x-min.png";
const waveGraphicMobile =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/bianchi-wave-mobile_2x-min.png";
const mobiImage =
  "https://res.cloudinary.com/loanpal/image/upload/w_650,q_auto,f_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/SWF-BLU-Drop-Max-Arialwide_2x-min.jpg";
const mobiImageMobile =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday/WF-BLU-Drop-Max-mobile_2x-min.jpg";

const GivingTuesday = ({ ...props }) => {
  const classes = useStyles();
  const initialState = {
    width: window.innerWidth,
    id: "",
    list: null,
    summary: undefined,
    partner: null,
    isBrowser: isBrowser,
    isMobile: isMobile,
  };

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setState({ width: window.innerWidth });
    };

    const getDonationsSummary = (campaignId) => {
      fetch(
        `https://ctiag78qk2.execute-api.us-west-2.amazonaws.com/givepower/campaign/donations/${campaignId}`
      )
        .then((res) => res.json())
        .then((res) => setState({ summary: res }));
    };

    async function onLoad() {
      if (!props) return;
      const id = props.match.params.id;
      console.log("id", id, Config.partners);
      const partner = Config.partners.filter(
        (p) => p.id.toLowerCase() === id.toLowerCase()
      );
      setState({
        id: id,
        partner: partner,
      });
      console.log("Partner", partner);
      if (partner.length === 0)
        window.location.href = "https://donate.givepower.org/donate-now";
      if (partner) {
        getDonationsSummary(partner[0].campaignId);
      }
      window.addEventListener("resize", handleWindowSizeChange);
    }
    onLoad();
    // eslint-disable-next-line
  }, []);

  const [state, updateState] = useState(initialState);
  const setState = (newState) =>
    updateState((prevState) => ({ ...prevState, ...newState }));
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const { id: partnerId, summary, partner } = state;
  // if (!partner) window.location.href = "https://donate.givepower.org/donate-now";
  if (!partner) return <></>;
  const logoImg = partner[0].icon;
  const logoSize = partner[0].size;
  const logoSizeM = partner[0].sizeM;
  const headerBg = partner[0].bg;
  const partnerName = partner[0].name;
  const gpLogoSize = partner[0].gpLogoSize;
  const gpLogoSizeM = partner[0].gpLogoSizeM;
  const progress = partner[0].progress;
  const goal = partner[0].goal;
  const goalOn = partner[0].goalOn;
  const offset = partner[0].offset;

  console.log(
    "Mobile",
    isMobile,
    isBrowser,
    width,
    isMobile && width >= 640 && width <= 1024
  );

  const firstLine = "1 DAY";
  const secondLine = "to change thousands of lives";
  const bannerData = {
    id: 0,
    image: mainBannerDesktop,
    imageClass: "mainFeaturedPostSmall",
    backgroundColor: "#ffffff",
    textContainer: "textContainerMiddle",
    text: firstLine,
    text2: secondLine,
    textClass: "text1Class",
    text2Class: "text2Class",
    columnLeft: 6,
    columnRight: 6,
    align: "left",
    menu: false,
  };

  const bannerDataTablet = {
    id: 0,
    image: mainBannerTablet,
    backgroundColor: "#ffffff",
    imageClass: "mainFeaturedPostContain",
    text: firstLine,
    text2: secondLine,
    textClassM: "whiteTextMargin",
    columnLeft: 12,
    columnRight: 12,
    align: "left",
    menu: true,
    topM: "6%",
  };

  const bannerDataMobile = {
    id: 0,
    image: mainBannerMobile,
    backgroundColor: "#ffffff",
    imageClass: "mainFeaturedPost",
    text: firstLine,
    text2: secondLine,
    textClassM: "whiteTextMargin",
    columnLeft: 12,
    columnRight: 12,
    align: "left",
    menu: true,
    topM: "6%",
  };

  return (
    <div
      style={{
        maxWidth: "1440px",
        margin: "0 auto",
        backgroundColor: "#ffffff",
        borderRight: "0.5px solid #fff4eb",
        borderLeft: "0.5px solid #fff4eb",
      }}
    >
      <div className={classes.root}>
        <Header
          image="img-container-hero-water"
          signup={false}
          float=""
          logoImg={logoImg}
          logoSize={logoSize}
          logoSizeM={logoSizeM}
          headerBg={headerBg}
          partnerName={partnerName}
          gpLogoSize={gpLogoSize}
          gpLogoSizeM={gpLogoSizeM}
          display={true}
          width={width}
        />
        {isMobile && width < 640 && <StarHero data={bannerDataMobile} />}
        {isMobile && width >= 640 && (
          <StarHero data={bannerDataTablet} />
        )}
        {isBrowser && width >= 640 && <StarHero data={bannerData} />}
        {(isMobile || (isBrowser && width < 1220)) && (
          <Box height="100%">
            <Grid
              container
              spacing={0}
              className={classes.donationFormContainer}
            >
              <DonationForm
                showDiv={false}
                classes={classes}
                partnerId={partnerId}
                summary={summary}
                progress={progress}
                goal={goal}
                goalOn={goalOn}
                offset={offset}
              />
            </Grid>
          </Box>
        )}
        {isBrowser && width >= 1220 && (
          <Grid container spacing={0} className={classes.leadContainer}>
            <Grid item xs={12} sm={6} className={classes.leadSpacing}>
              {/* <Box className={`${classes.lead} ${classes.whiteText}`}>
                More than 1 billion people currently live in water scarce regions.
              </Box> */}
              <Box className={`${classes.paragraph}`}>
                A donation of just $20 can give one person in need access to
                clean water for 20 years.
              </Box>
              <Box className={classes.paragraph}>
                <strong>
                  How many lives do you want to change this Giving Tuesday?
                </strong>
              </Box>
              <Box className={classes.paragraph}>
                Thanks to a generous matching gift from the Schumacher family,
                all donations made on December 1st will be matched up to $25,000
              </Box>
            </Grid>
            <DonationForm
              showDiv={true}
              classes={classes}
              partnerId={partnerId}
              summary={summary}
              progress={progress}
              goal={goal}
              goalOn={goalOn}
              offset={offset}
            />
          </Grid>
        )}
        {(isMobile || (isBrowser && width < 1220)) && (
          <Grid container spacing={0} className={classes.leadContainer}>
            <Grid item xs={12} className={classes.leadSpacing}>
              {/* <Box className={`${classes.lead} ${classes.whiteText}`}>
                  More than 1 billion people currently live in water scarce regions.
                </Box> */}
              <Box className={`${classes.paragraph}`}>
                A donation of just $20 can give one person in need access to
                clean water for 20 years.
              </Box>
              <Box className={classes.paragraph}>
                <strong>
                  How many lives do you want to change this Giving Tuesday?
                </strong>
              </Box>
              <Box className={classes.paragraph}>
                Thanks to a generous matching gift from the Schumacher family,
                all donations made on December 1st will be matched up to
                $25,000.
              </Box>
            </Grid>
          </Grid>
        )}
        <GlassContainer
          classes={classes}
          isMobile={isMobile}
          isBrowser={isBrowser}
          width={width}
          glassWaterMobile={glassWaterMobile}
          glassWater={glassWater}
        />
        <WaveContainer
          classes={classes}
          isMobile={isMobile}
          width={width}
          waveGraphicMobile={waveGraphicMobile}
          waveGraphic={waveGraphic}
        />
        <VideoContainer
          classes={classes}
          boysWater={boysWater}
          isMobile={isMobile}
          isBrowser={isBrowser}
        />
        <MobiContainer
          classes={classes}
          isMobile={isMobile}
          isBrowser={isBrowser}
          width={width}
          mobiImageMobile={mobiImageMobile}
          mobiImage={mobiImage}
        />
        <WhoQuoteContainer classes={classes} />
      </div>
    </div>
  );
};

export default GivingTuesday;
