import React from 'react';
import {
  isMobile,
} from "react-device-detect";
import { Grid }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import gpLogoWhite from 'components/shared/images/givepower_TM_logo_Horizontal_White.svg';
import gpLogoBlack from 'components/shared/images/givepower_TM_logo_Horizontal_Black.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  container: {
    textAlign: 'center'
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  marginGrid: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const Header = ({ logoImg, logoSize, logoSizeM, headerBg, gpLogoSize, gpLogoSizeM, partnerName, display, float, signup, secure }) => {
  const classes = useStyles();

  return (
      <header className={`${classes.root} ${display ? classes.show : classes.hide}`}>
        <nav className={headerBg} style={{maginTop: '12px'}}>
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12} className={classes.marginGrid}>
              <a href="https://givepower.org" style={{ borderBottom: 'none' }}>
                <img
                  style={{height: isMobile ? gpLogoSizeM : gpLogoSize}}
                  className={'logo ' + float}
                  src={`${headerBg === 'bg-white' ? gpLogoBlack : gpLogoWhite}`}
                  alt="GivePower"
                />
              </a>
              <img className="logo-partner" src={logoImg} style={{width: isMobile ? logoSizeM : logoSize, margin: '10px'}} alt={partnerName}/>
              {signup && (
                <ul className="main-nav pull-right js--main-nav">
                  <li>
                    <a href="https://visitor.r20.constantcontact.com/manage/optin?v=001fbezbEaKxpAMtdU3ZsXL4i2jYB_TjGkDB7rzBHK_yWlS1SlmKDAKOfCF0q0fUouMAk3_QSkD4_67ELVrFPG_DfI_vKcH363R5WoxK1OfTAOl_m4o_GhfORVyKP0Juuo0yEEs1qFpPL-DAItam_LROt_jNpzNz7paqaR1UXShByhOaqQCsiUSrOieIpj_EkKADv7OHf6QLu8%3D">
                      <i
                        className="fa fa-2x fa-caret-right align-top text-orange mr-2"
                        aria-hidden="true"
                      />
                      Sign up{' '}
                      <span className="hidden-md-down">
                        for GivePower Newsletter
                      </span>
                    </a>
                  </li>
                </ul>
              )}
              {secure && (
                <ul className="pull-right mt-2">
                <h5 className="heavy text-blue text-center pt-2 secure-donation">
                <i
                  className="fa fa-lock text-green mr-1"
                  aria-hidden="true"
                />
                <span className="text-white">Secure Donation</span>
              </h5>
              </ul>
              )}
            </Grid>
          </Grid>
        </nav>
      </header>
    );
}

export default Header;
