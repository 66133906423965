import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },
  paperCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  padding3: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  lead: {
    textAlign: "left",
    font: "normal normal 900 20px/24px lato, sanserif",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 900 20px/24px lato, sanserif",
      textAlign: "center",
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 640 16px/20px lato, sanserif",
      textAlign: "left",
      padding: theme.spacing(2),
      margin: "0 5%",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 900 32px/40px lato, sanserif",
      textAlign: "left",
    },
  },
  lead2: {
    textAlign: "left",
    font: "normal normal 700 32px/38px lato, sanserif",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      font: "normal normal 640 20px/24px lato, sanserif",
      textAlign: "left",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
      font: "normal normal 640 24px/28px lato, sanserif",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 700 32px/38px lato, sanserif",
      textAlign: "left",
    },
  },
  lead2Mobi: {
    textAlign: "left",
    font: "normal normal 700 32px/38px lato, sanserif",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 640 20px/24px lato, sanserif",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
      font: "normal normal 640 24px/28px lato, sanserif",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 700 32px/38px lato, sanserif",
      textAlign: "left",
    },
  },
  subTitle: {
    textAlign: "left",
    font: "normal normal 700 28px/32px lato, sanserif",
    width: "100%",

    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 640 24px/35px lato, sanserif",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 640 24px/28px lato, sanserif",
      textAlign: "left",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 700 28px/32px lato, sanserif",
      textAlign: "left",
    },
  },
  paragraph2: {
    font: "normal normal 400 26px/30px lato, sanserif",
    textAlign: "left",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 16px/18px lato, sanserif",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      textAlign: "left",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 400 20px/24px lato, sanserif",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      textAlign: "left",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 400 25px/30px lato, sanserif",
      textAlign: "left",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  paragraph2StepA: {
    maxWidth: "66%",
    display: "block",
  },
  paragraph2StepB: {
    maxWidth: "75%",
    display: "block",
  },
  paragraph2StepC: {
    maxWidth: "100%",
    display: "block",
  },
  paragraph2Mobi: {
    font: "normal normal 400 24px/30px lato, sanserif",
    textAlign: "left",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 16px/18px lato, sanserif",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 400 23px/26px lato, sanserif",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 400 24px/30px lato, sanserif",
      textAlign: "left",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(1),
    },
  },
  leadHeavy: {
    textAlign: "left",
    font: "normal normal 900 50px/60px lato, sanserif",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 900 32px/35px lato, sanserif",
      textAlign: "left",
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      font: "normal normal 900 38px/45px lato, sanserif",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      font: "normal normal 900 50px/60px lato, sanserif",
    },
  },
  whiteText: {
    color: "#ffffff",
  },
  greyText: {
    color: "#7E7E7E",
  },
  glassTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  waterText: {
    textAlign: "right",
    paddingLeft: theme.spacing(3),
    font: "normal normal 900 50px/75px lato, sanserif",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 900 45px/65px lato, sanserif",
    },
  },
  underWater: {
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "32px",
      marginBottom: "20px",
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "40px",
      textAlign: "left",
      margin: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  paragraph: {
    padding: theme.spacing(3),
    font: "normal normal 400 23px/30px lato, sanserif",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      font: "normal normal 500 18px/22px lato, sanserif",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      font: "normal normal 400 22px/26px lato, sanserif",
      textAlign: "left",
      margin: "0 5%",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      font: "normal normal 400 23px/30px lato, sanserif",
      textAlign: "left",
    },
  },
  paragraphSmall: {
    padding: theme.spacing(3),
    font: "normal normal 400 14px/18px lato, sanserif",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      font: "normal normal 400 14px/18px lato, sanserif",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      font: "normal normal 400 22px/26px lato, sanserif",
      textAlign: "left",
      margin: "0 5%",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      font: "normal normal 400 23px/30px lato, sanserif",
      textAlign: "left",
    },
  },
  paragraphMedium: {
    textAlign: "left",
    font: "normal normal 500 26px/36px lato, sanserif",
  },
  paragraphBold: {
    padding: theme.spacing(3),
    textAlign: "left",
    font: "normal normal 900 27px/33px lato, sanserif",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      font: "normal normal 500 24px/28px lato, sanserif",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      font: "normal normal 900 27px/33px lato, sanserif",
      textAlign: "left",
      margin: "0 5%",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      font: "normal normal 900 27px/33px lato, sanserif",
      textAlign: "left",
    },
  },
  quote: {
    font: "normal normal 640 30px/55px futura-pt, sanserif",
    fontStyle: "oblique",
    color: "#000000",
    maxWidth: "680px",
    textAlign: "center",
    margin: "0 auto",
    marginTop: theme.spacing(5),
  },
  author: {
    font: "normal normal 640 20px/22px futura-pt, sanserif",
    fontStyle: "oblique",
    marginTop: theme.spacing(2),
  },
  secure: {
    color: "#103fa1",
    font: "normal normal 900 25px/25px futura-pt, sans-serif",
    margin: "25px 0",
  },
  spaceText: {
    marginLeft: "5px",
    position: "relative",
    top: "2px",
  },
  donationForm: {
    padding: "5px",
    overflow: "visible",
  },
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
  },
  headerUnderline: {
    font: "normal normal 640 30px/36px futura-pt, sans-serif",
    textDecoration: "underline",
    color: "#000000",
  },
  divider: {
    marginTop: "80px",
    marginBottom: "80px",
    color: "#535353 !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "80px",
    },
  },
  marginTop4: {
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "1rem",
    },
  },
  marginTop6: {
    marginTop: "6rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "6rem",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "6rem",
    },
  },
  statsBoxLarge: {
    font: "normal normal 500 30px/38px futura-pt, sans-serif",
    width: "85%",
    maxWidth: "920px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      font: "normal normal 500 24px/32px futura-pt, sans-serif",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 500 30px/38px futura-pt, sans-serif",
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 500 30px/38px futura-pt, sans-serif",
      width: "85%",
    },
  },
  imgSmall: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  fontH1: {
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
  },
  donationFormIFrame: {
    backgroundColor: "#ffffff",
    border: "0px none transparent",
    overflow: "hidden",
    display: "inline-block",
    visibility: "visible",
    margin: "0px",
    padding: "0px",
    height: "845px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "900px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "850px",
    },
    [theme.breakpoints.up("md")]: {
      height: "845px",
    },
  },
  donationFormContainer: {
    position: "relative",
    padding: "0px 45px 0 80px",
    zIndex: "100",
    marginTop: "-335px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      padding: "0px 45px 0 80px",
      marginTop: "-335px",
    },
  },
  donationFormPaper: {
    backgroundColor: "#ffffff",
    boxShadow: "10px 10px 10px 5px rgba(0, 0, 0, .1)",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
    [theme.breakpoints.up("sm")]: {
      boxShadow: "none",
    },
    [theme.breakpoints.up("md")]: {
      boxShadow: "10px 10px 10px 5px rgba(0, 0, 0, .1)",
    },
  },
  progressBar: {
    padding: theme.spacing(3),
    color: "#000000 !important",
    border: "1px solid #cccccc",
    boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, .1)",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      boxShadow: "none",
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, .1)",
      padding: theme.spacing(3),
    },
  },
  leadContainer: {
    backgroundColor: "#1A3343",
    padding: theme.spacing(3),
    color: "#ffffff !important",
    height: "490px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      height: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      height: "490px",
    },
  },
  leadSpacing: {
    display: "flex",
    alignItems: "baseline",
    flexDirection: "column",
    backgroundColor: "#3343",
    color: "#ffffff !important",
  },
  glassFeature: {
    color: "#7E7E7E",
    backgroundColor: "#ffffff",
    backgroundSize: "cover",
    height: "888px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "contain",
      height: "364px",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      height: "640px",
    },
    [theme.breakpoints.up("md")]: {
      backgroundSize: "cover",
      height: "888px",
    },
  },
  glassContainerGrid: {
    height: "100%",
  },
  glassContainerTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      marginBottom: "330px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      marginBottom: "0",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      marginBottom: "0",
    },
  },
  waveContainer: {
    marginTop: "-110px",
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: "-160px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "-110px",
    },
  },
  waveGraphicFeature: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
    backgroundColor: "transparent",
    backgroundSize: "cover",
    height: "390px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      height: "206px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "325px",
    },
    [theme.breakpoints.up("md")]: {
      height: "390px",
    },
  },
  waveText: {
    font: "normal normal 500 36px/55px lato, sanserif",
    margin: "0 16%",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 20px/23px lato, sanserif",
      margin: "0 5%",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 500 30px/42px lato, sanserif",
      margin: "0 10%",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 500 36px/55px lato, sanserif",
      margin: "0 16%",
    },
  },
  waveTextBlackItalic: {
    font: "normal normal 900 50px/60px lato, sanserif",
    fontStyle: "italic",
  },
  videoContainer: {
    // marginTop: '-90px',
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1A3343",
    color: "#ffffff",
    zIndex: 0,
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginTop: "-22px",
      height: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "-62px",
      height: "630px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      //   marginTop: '-90px',
      height: "720px",
      paddingTop: "64px",
    },
  },
  videoGrid: {
    padding: "40px 50px",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      marginTop: "22px",
      padding: "25px 15px 0px 15px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "25px 70px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "40px 50px",
    },
  },
  videoBox: {
    position: "relative",
    // top: '100px',
    // [theme.breakpoints.down('sm')]: {
    //   top: '50px',
    // },
    // [theme.breakpoints.up('sm')]: {
    //   top: '100px',
    // },
    // [theme.breakpoints.up('md')]: {
    //   top: '100px',
    // }
  },
  textGrid: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },
  textBox: {
    marginRight: "15%",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "0",
      textAlign: "center",
      width: "100%",
      margin: "0 auto",
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "15%",
    },
  },
  boys: {
    border: "none",
    opacity: 1,
  },
  mobiContainer: {
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#7E7E7E",
    zIndex: 0,
    height: "780px",
    [theme.breakpoints.down("sm")]: {
      height: "535px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "610px",
    },
  },
  mobiContainerInside: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      margin: "20px 0",
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
  mobiTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "center",
    height: "100%",
    width: "90%",
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down("ms")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      justifyContent: "left",
      width: "90%",
      margin: "0 auto",
      paddingBottom: "20px",
      paddingTop: "40px",
    },
    [theme.breakpoints.up("md")]: {
      width: "90%",
      alignItems: "left",
      justifyContent: "left",
    },
  },
  bianchiShadow: {
    boxShadow: "30px 30px #47C0C0",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
    },
    [theme.breakpoints.up("sm")]: {
      boxShadow: "30px 30px #47C0C0",
    },
    [theme.breakpoints.up("md")]: {
      boxShadow: "30px 30px #47C0C0",
    },
  },
  mobiImageBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "25px",
    width: "100%",
  },
  mobiImage: {
    width: "450px",
  },
  whoQuoteContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    zIndex: 0,
    height: "290px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "40px",
      marginTop: "35px",
      height: "400px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "220px",
    },
    [theme.breakpoints.up("md")]: {
      height: "290px",
      marginBottom: "40px",
    },
  },
  paperCenterWho: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    color: "#ffffff !important",
    backgroundColor: "#F9AD1A !important",
  },
  whoQuoteBackground: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    color: "#ffffff !important",
    backgroundColor: "#F9AD1A !important",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paragraphMediumItalic: {
    font: "normal italic 500 25px/35px lato, sanserif",
    textAlign: "justify",
    display: "flex",
    alignItems: "center",
    margin: "0 5%",
    color: "#ffffff !important",
    backgroundColor: "#F9AD1A !important",
    [theme.breakpoints.down("sm")]: {
      margin: "0 5%",
      font: "normal italic 500 20px/24px lato, sanserif",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "0 5%",
      font: "normal italic 500 22px/24px lato, sanserif",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 5%",
      font: "normal italic 500 25px/35px lato, sanserif",
    },
  },
}));

export default useStyles;
