import React, { useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Home from 'components/views/home';
import NavBar from 'components/shared/nav-bar';
import Footer from 'components/shared/footer';
import Loanpal from 'components/views/loanpal-mobi';
import GivePartners from 'components/views/givepartners';
import GivingTuesday from 'components/views/giving-tuesday';

import '../../App.css';

const Router = ({ location, history, viewContainerRef }) => {
    const { pathname } = location;

    console.log('pathname router', pathname);

    useEffect(() => {
      try {
        if (viewContainerRef && viewContainerRef.current) viewContainerRef.current.scrollTop = 0; // eslint-disable-line no-param-reassign
      } catch (e) {
        console.error('Failed to scroll top', e);
      }
    }, [pathname, viewContainerRef]);

    const RouteWithProps = ({ path, history, exact, Component, ...props }) =>
       <Route exact={exact} path={path} render={routerProps => <Component {...{ ...props, ...routerProps, ...location }} />} />;
    return (
    <>
       {(pathname === "/" || pathname === '/donate-now') && <NavBar pathname={pathname} />}
        <Switch>
            <RouteWithProps path="/" exact Component={Home} />
            {/* <RouteWithProps path="/donations" Component={Donations} /> */}
            <Route path="/donate-now" component={Home} />
            <Route path="/loanpalmobi" component={Loanpal} />
            <Route path="/:id" exact component={GivePartners} />
            <Route path="/gt/:id" exact component={GivingTuesday} />
            <Redirect to="/" />
        </Switch>
        <Footer pathname={pathname} />
    </>
    )
};

  export default withRouter(Router);