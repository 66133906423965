import React from "react";
import { Paper, Grid, Box } from "@material-ui/core";
import ProgressBar from "components/shared/progress-bar";

const DonationForm = ({
  classes,
  partnerId,
  summary,
  progress,
  goal,
  offset,
  showDiv,
}) => {
  console.log("DonationForm", summary);
  return (
    <Grid item xs={12} sm={12} md={6} id="donate" name="donate">
      <div className={showDiv ? classes.donationFormContainer : ""}>
        <Paper
          elevation={0}
          className={`${classes.paper} ${classes.donationFormPaper}`}
        >
          <Box className={classes.progressBar}>
            {summary && (
              <ProgressBar
                progress={progress}
                goal={goal}
                progressBarColor="#F9AD1A"
                total_amount_in_cents={summary.total_amount_in_cents - offset}
              />
            )}
          </Box>
          <Box className={classes.donationForm}>
            {partnerId.toLowerCase() === "givepower" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_7b92a59fd614&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "loanpal" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_d1d60b795594&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22Loanpal%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
              />
            )}
            {partnerId.toLowerCase() === "titan080" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_baab28b3efb2&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "empire070" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_edf4fb1a1f92&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "dynamic090" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_2bde4c1cfd11&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "blueraven060" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_c610c259f74e&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "ourworldenergy050" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_709d2e71c04f&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "skyline0110" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_ee92185daf13&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "expertsolarpros020" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_fc7f328850c8&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "bellelectricalsystems040" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_05a5b94d14d4&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "sunworks010" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_c5999c6f34d9&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "newpower030" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_a1119f60edcc&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
            {partnerId.toLowerCase() === "texassolarintegrated0220" && (
              <iframe
                className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                frameBorder="0"
                allowtransparency="true"
                title="Join the Charge"
                src="https://cdn.donately.com/core/4.0/donate-form.html?form_id=frm_314472c4315b&amp;account_id=act_d6b0b0246254&amp;stripe_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;"
              />
            )}
          </Box>
        </Paper>
      </div>
    </Grid>
  );
};

export default DonationForm;
