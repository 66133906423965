import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Box, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: "150px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "150px",
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  donationForm: {
    padding: "5px",
    backgroundColor: "#efefef",
    overflow: "hidden",
    scroll: "none",
  },
  questions: {
    fontSize: "90%",
    padding: "20px",
  },
  link: {
    "&:link, &:visited": {
      color: "#e79913",
      textDecoration: "none",
      paddingBottom: "1px",
      borderBottom: "1px solid #e79913",
      transition: "border-bottom .2s,color .2s",
    },
    "&:hover, &:active": {
      color: "#555555",
      borderBottom: "1px solid transparent",
    },
  },
  secure: {
    color: "#3355c1",
    font: "normal normal 900 25px/25px futura-pt, sans-serif",
    margin: "25px 0",
  },
  spaceText: {
    marginLeft: "5px",
    position: "relative",
    top: "2px",
  },
  imgJoinTheCharge: {
    marginTop: "-0px",
  },
  heavy: {
    font: "normal normal 700 32px/48px futura-pt, sans-serif",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 700 28px/36px futura-pt, sans-serif",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 700 32px/48px futura-pt, sans-serif",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 700 32px/48px futura-pt, sans-serif",
    },
  },
  demi: {
    font: "normal normal 500 20px/35px futura-pt, sans-serif",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 20px/35px futura-pt, sans-serif",
      marginBottom: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 500 20px/35px futura-pt, sans-serif",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 500 20px/35px futura-pt, sans-serif",
    },
  },
  paragraph: {
    font: "normal normal 500 24px/35px futura-pt, sans-serif",
    textAlign: "justify !important",
    wordBreak: "keep-all",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 400 18px/26px futura-pt, sans-serif",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 500 24px/35px futura-pt, sans-serif",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 500 24px/35px futura-pt, sans-serif",
    },
  },
  left: {
    textAlign: "left",
    margin: "30px 10px",
    color: "#535353",
    [theme.breakpoints.down("sm")]: {
      margin: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "30px 10px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "30px 10px",
    },
  },
  center: {
    textAlign: "center",
    margin: "20px 10px",
    color: "#535353",
    [theme.breakpoints.down("sm")]: {
      margin: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 10px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "20px 10px",
    },
  },
  right: {
    textAlign: "right",
    margin: "30px 10px",
    color: "#535353",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      margin: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "30px 10px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "30px 10px",
    },
  },
  gridCenter: {
    display: "flex",
    flexDirection: "row",
    justify: "flex-start",
    alignItems: "center",
  },
  footer: {
    font: "normal normal 400 18px/25px futura-pt, sans-serif",
    marginTop: "40px",
  },
  isMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  isDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
}));

const Home = ({ ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7}>
          <Paper elevation={0} className={classes.paper}>
            <Box>
              <img
                src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1579106842/givepower/donate/bethepower_nepaliboys_tall.jpg"
                className={classes.img}
                alt="Be the power for change"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Paper elevation={0} className={classes.paper}>
            <Box className={classes.donationForm}>
              <Box className={classes.secure}>
                <FontAwesomeIcon icon={faLock} color="#3355c1" size="xs" />
                <span className={classes.spaceText}>Secure Donation</span>
              </Box>
              <iframe
                className="donately-donation-iframe"
                frameBorder="0"
                allowtransparency="true"
                title="Be the Power for Change"
                style={{
                  backgroundColor: "transparent",
                  border: "0px none transparent",
                  overflow: "hidden",
                  display: "inlineBlock",
                  visibility: "visible",
                  margin: "0px",
                  padding: "0px",
                  height: "700px",
                  width: "100%",
                }}
                src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_anonymous=true&amp;donately_campaign_id=cmp_5774d85abad0&amp;donately_currency=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D"
              />
              <Box className={classes.questions}>
                For questions about or changes to an existing donation, please
                send an email to{" "}
                <a className={classes.link} href="mailto:finance@givepower.org">
                  finance@givepower.org
                </a>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={0} className={classes.paper}>
            <Box className={classes.imgJoinTheCharge}>
              <img
                src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/joinCharge_10_computers.jpg"
                className={classes.img}
                style={{ width: "120%" }}
                alt="Be the power for change"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridCenter}>
          <Paper elevation={0} className={classes.paper}>
            <Box className={classes.left}>
              <Typography variant="h4" className={classes.heavy}>
                BECOME A SUSTAINING SUPPORTER
              </Typography>
              <Typography variant="h5" className={classes.demi}>
                Make an impact every month
              </Typography>
              <Box className={classes.paragraph}>
                By supporting GivePower every month, you are supporting all of
                our life changing programs. From providing people with access to
                clean water, to powering schools, to delivering emergency relief
                to a disaster stricken community — your on-going support enables
                us to do what is necessary, when it's necessary.
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.isMobile}>
        <Paper elevation={0} className={classes.paper}>
          <Box>
            <img
              src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/20for20_TM.jpg"
              className={classes.img}
              alt="Be the power for change"
            />
          </Box>
        </Paper>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className={classes.gridCenter}>
          <Paper elevation={0} className={classes.paper}>
            <Box className={classes.right}>
              <Typography variant="h4" className={classes.heavy}>
                PROVIDE ACCESS TO CLEAN WATER
              </Typography>
              <Box className={classes.paragraph}>
                Help us build more Solar Water Farm™ Max's in communities around
                the world. Each solar-powered water desalination system provides
                access to clean water for approximately 35,000 people each day.
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.isDesktop}>
          <Paper elevation={0} className={classes.paper}>
            <Box>
              <img
                src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/20for20_TM.jpg"
                className={classes.img}
                alt="Be the power for change"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={0} className={classes.paper}>
              <Box className={classes.imgJoinTheCharge}>
                <img
                  src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/boySign.jpg"
                  className={classes.img}
                  style={{ width: "120%" }}
                  alt="Be the power for change"
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridCenter}>
            <Paper elevation={0} className={classes.paper}>
              <Box className={classes.left}>
                <Typography variant="h4" className={classes.heavy}>
                  EMPOWER A STUDENT
                </Typography>
                <Typography variant="h5" className={classes.demi}>
                  Make an impact every month
                </Typography>
                <Box className={classes.paragraph}>
                  Bringing power to a school allows kids to extend their
                  studies, gain access to digital learning tools, and opens the
                  school for adult education in the evenings. You can provide
                  access to better education by helping us bring solar power.
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} className={classes.footer}>
          <Paper elevation={0} className={classes.paper}>
            <Box className={classes.center}>
              Canadian donors can make a tax-deductible donation to GivePower
              through our partner charity{" "}
              <a href="https://chimp.net/groups/givepower">RC Forward</a>.
            </Box>
            <Box className={classes.center}>
              Are you a company that values giving back?{" "}
              <a href="https://givepower.org/get-involved/">Partner with us</a>!
            </Box>
            <Box className={classes.center}>
              Interested in funding an entire project? Please contact us at{" "}
              <a href="mailto:donate@givepower.org">donate@givepower.org</a>, to
              discuss your goals.
            </Box>
            <Box className={classes.center}>
              GivePower is a 501(c)(3) charitable organization, and all
              donations made are tax-deductible for donors located in the United
              States. Our federal EIN is 47-1265705
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
