import React, { useState, useEffect } from "react";
import Header from "components/shared/start-hero/header";
import { partners } from "utils/config";
import ProgressBar from "components/shared/progress-bar";
import StartHero from "components/shared/start-hero";
import StartHeroMobile from "components/shared/start-hero/mobile";
import { Paper, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import { isBrowser, isMobile } from "react-device-detect";

const boysWater =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/movieThumbnail-play-min.png";
const desalUnit =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1605380028/givepower/donate/desal-min.png";
const icons =
  "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/givepower/donate/icons-min.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: "10px 10px",
      width: "85%",
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  paperCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  lead: {
    textAlign: "left",
    font: "normal normal 600 28px/28px futura-pt, sanserif",
    color: "#000000",
    fontStyle: "italic",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 20px/20px futura-pt, sanserif",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 600 28px/28px futura-pt, sanserif",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 600 28px/28px futura-pt, sanserif",
    },
  },
  paragraph: {
    font: "normal normal 500 22px/36px futura-pt, sanserif",
    textAlign: "justify !important",
    wordBreak: "keep-all",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 400 18px/22px futura-pt, sanserif",
    },
  },
  paragraphMedium: {
    font: "normal normal 500 26px/36px futura-pt, sanserif",
    textAlign: "justify",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  paragraphMediumItalic: {
    font: "normal normal 500 23px/30px futura-pt, sanserif",
    textAlign: "justify",
    fontStyle: "italic",
    display: "flex",
    alignItems: "center",
    margin: "0 15%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 5%",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "0 15%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 15%",
    },
  },
  quote: {
    font: "normal normal 600 30px/55px futura-pt, sanserif",
    fontStyle: "oblique",
    color: "#000000",
    maxWidth: "680px",
    textAlign: "center",
    margin: "0 auto",
    marginTop: theme.spacing(5),
  },
  author: {
    font: "normal normal 600 20px/22px futura-pt, sanserif",
    fontStyle: "oblique",
    marginTop: theme.spacing(2),
  },
  secure: {
    color: "#103fa1",
    font: "normal normal 900 25px/25px futura-pt, sans-serif",
    margin: "25px 0",
  },
  spaceText: {
    marginLeft: "5px",
    position: "relative",
    top: "2px",
  },
  donationForm: {
    padding: "5px",
    backgroundColor: "#efefef",
    overflow: "hidden",
    scroll: "none",
  },
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
  },
  boys: {
    padding: "1rem",
    border: "none",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      padding: "1rem !important",
    },
  },
  headerUnderline: {
    font: "normal normal 600 30px/36px futura-pt, sans-serif",
    textDecoration: "underline",
    color: "#000000",
  },
  divider: {
    marginTop: "80px",
    marginBottom: "80px",
    color: "#535353 !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "80px",
    },
  },
  fontMobile: {
    marginTop: "80px",
    marginBottom: "80px",
    color: "#535353 !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      fontSize: "80% !important",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "80px",
    },
  },
  marginTop4: {
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "1rem",
    },
  },
  marginTop6: {
    marginTop: "6rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "6rem",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "6rem",
    },
  },
  statsBoxLarge: {
    font: "normal normal 500 30px/38px futura-pt, sans-serif",
    width: "85%",
    maxWidth: "920px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      font: "normal normal 500 24px/32px futura-pt, sans-serif",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 500 30px/38px futura-pt, sans-serif",
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 500 30px/38px futura-pt, sans-serif",
      width: "85%",
    },
  },
  imgSmall: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  fontH1: {
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
  },
  donationFormIFrame: {
    backgroundColor: "#EFEFEF",
    border: "0px none transparent",
    overflow: "hidden",
    display: "inline-block",
    visibility: "visible",
    margin: "0px",
    padding: "0px",
    height: "955px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "795px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "815px",
    },
    [theme.breakpoints.up("md")]: {
      height: "955px",
    },
  },
}));

const GivePartnersPage = ({ ...props }) => {
  const classes = useStyles();
  const initialState = {
    width: window.innerWidth,
    id: "",
    list: null,
    summary: undefined,
    partner: null,
    isBrowser: isBrowser,
    isMobile: isMobile,
  };

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setState({ width: window.innerWidth });
    };

    const getDonationsSummary = (campaignId) => {
      fetch(
        `https://ctiag78qk2.execute-api.us-west-2.amazonaws.com/givepower/campaign/donations/${campaignId}`
      )
        .then((res) => res.json())
        .then((res) => setState({ summary: res }));
    };

    async function onLoad() {
      if (!props) return;
      const id = props.match.params.id;
      console.log("id", id, partners);
      const partner = partners.filter(
        (p) => p.id.toLowerCase() === id.toLowerCase()
      );
      setState({
        id: id,
        partner: partner,
      });
      console.log("Partner", partner);
      // if (partner.length === 0) window.location.href = 'https://donate.givepower.org/donate-now';
      if (partner) {
        getDonationsSummary(partner[0].campaignId);
      }
      window.addEventListener("resize", handleWindowSizeChange);
    }
    onLoad();
    // eslint-disable-next-line
  }, []);

  const [state, updateState] = useState(initialState);
  const setState = (newState) =>
    updateState((prevState) => ({ ...prevState, ...newState }));
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const { id: partnerId, summary, partner } = state;
  // if (!partner) window.location.href = "https://donate.givepower.org/donate-now";
  if (!partner) return <></>;
  const logoImg = partner[0].icon;
  const logoSize = partner[0].size;
  const logoSizeM = partner[0].sizeM;
  const headerBg = partner[0].bg;
  // const gpLogo = partner[0].gpLogo;
  const partnerName = partner[0].name;
  const title = partner[0].title;
  const description = partner[0].description;
  const description2 = partner[0].description2;
  const description3 = partner[0].description3;
  const gpLogoSize = partner[0].gpLogoSize;
  const gpLogoSizeM = partner[0].gpLogoSizeM;
  const progress = partner[0].progress;
  const goal = partner[0].goal;
  const donateButtonOn = partner[0].donateButtonOn;

  console.log("****** state", state);

  const firstLine = "CLEAN WATER";
  const secondLine = "TROUGH";
  const thirdLine = "CLEAN ENERGY";
  const fourthLine =
    "GivePower's Solar Water Farms&trade; are sustainable creating access to clean water in water-scarce regions around the world.";
  const bannerData = {
    id: 0,
    image:
      "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/givepower/donate/giving-tuesday-min_1440.jpg",
    imageClass: "mainFeaturedPostSmall",
    backgroundColor: "#ffffff",
    textContainer: "textContainerMiddle",
    text: firstLine,
    text2: secondLine,
    text3: thirdLine,
    text4: fourthLine,
    donateButton: donateButtonOn,
    textClass: "upperTextThinMiddle",
    text2Class: "lowerTextBoldMiddle",
    text4Class: "underTextBoldSmall",
    columnLeft: 6,
    columnRight: 6,
    align: "left",
    menu: false,
    top: 0,
  };

  const bannerDataMobile = {
    id: 0,
    imageMobile:
      "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1600390850/givepower/donate/giving-tuesday-min_640.jpg",
    backgroundColor: "#ffffff",
    imageClass: "mainFeaturedPostContain",
    text: firstLine,
    text2: secondLine,
    text3: thirdLine,
    text4: fourthLine,
    textClassM: "whiteTextMargin",
    textClassM2: "middleTextMargin",
    textClassM3: "lowerTextMargin",
    columnLeft: 10,
    columnRight: 2,
    align: "left",
    menu: true,
    topM: "6%",
  };

  return (
    <div
      style={{
        maxWidth: "1440px",
        margin: "0 auto",
        backgroundColor: "#ffffff",
        width: "108%",
        borderRight: "0.5px solid #fff4eb",
        borderLeft: "0.5px solid #fff4eb",
      }}
    >
      <Header
        image="img-container-hero-water"
        signup={false}
        float=""
        logoImg={logoImg}
        logoSize={logoSize}
        logoSizeM={logoSizeM}
        headerBg={headerBg}
        partnerName={partnerName}
        gpLogoSize={gpLogoSize}
        gpLogoSizeM={gpLogoSizeM}
        display={true}
      />
      {isMobile && <StartHeroMobile data={bannerDataMobile} />}
      {isBrowser && width >= 600 && <StartHero data={bannerData} />}
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            {title && <p className={classes.lead}>{title}</p>}
            {title === undefined && (
              <p className={classes.lead}>
                In this season of giving, you have the power to make a bigger
                impact than ever.
              </p>
            )}

            {description && <p className={classes.paragraph}>{description}</p>}
            {description2 && (
              <p className={classes.paragraph}>{description2}</p>
            )}
            {description3 && (
              <p className={classes.paragraph}>{description3}</p>
            )}

            {description === undefined && partnerName === "FlaSEIA" && (
              <>
                <p className={classes.paragraph}>
                  GivePower provides solar-powered solutions in the developing
                  world to secure clean drinking water, food production, safety,
                  and education. Join the charge today to assist our neighbors
                  in the Bahamas who are still suffering from the recent
                  hurricane’s effects. Every dollar you donate will go directly
                  to help Bahamians with solar energy solutions to power life’s
                  most essential needs. And this Giving Tuesday, December 3rd,
                  your donation will have double the impact! On this day,
                  Loanpal, the World-Positive Lender, will generously match
                  every donation made up to a collective maximum of $100,000.
                </p>
                <p className={classes.paragraph}>
                  Together, we can change lives!
                </p>
              </>
            )}
            {description === undefined && partnerName !== "FlaSEIA" && (
              <>
                <p className={classes.paragraph}>
                  GivePower's new Solar Water Farm™ technology can deliver
                  clean, healthy drinking water to countless people in critical
                  need. In fact, a $20 donation can provide access to clean
                  water to someone in need for 20 years! And this Giving
                  Tuesday, your donation will have double the impact! On
                  December 3rd,{" "}
                  {partnerName === "Loanpal" && (
                    <span>donate to GivePower</span>
                  )}{" "}
                  {partnerName !== "Loanpal" && (
                    <span>
                      join <span className="demi text-dark">{partnerName}</span>{" "}
                      and donate to the GivePower Foundation
                    </span>
                  )}{" "}
                  and Loanpal will generously match every donation made up to a
                  collective maximum of $100,000.
                </p>
                <p className={classes.paragraph}>
                  How many years of life-saving water are you ready to give this
                  Giving Tuesday?
                </p>
                <p className={classes.paragraph}>
                  Together, we can change thousands of lives!
                </p>
              </>
            )}
            {partnerName !== "Loanpal" && title === undefined && (
              <p className={classes.lead}>
                In this season of giving, you have the power to make a bigger
                impact than ever.
              </p>
            )}
            {partnerName === "FlaSEIA" && (
              <>
                <p className={classes.paragraph}>
                  GivePower’s Solar Water Farm™ technology can deliver clean,
                  healthy drinking water to thousands in critical need. In fact,
                  a $20 donation can provide access to clean water to someone in
                  need for 20 years! And this Giving Tuesday, your donation can
                  have double the impact. Watch this video to see the impact of
                  our most recent Solar Water Farm Max project in Likoni, Kenya.
                </p>
                <p className={classes.paragraph}>
                  Together, we can change lives!
                </p>
              </>
            )}
            {partnerName !== "FlaSEIA" &&
              partnerName !== "Loanpal" &&
              description === undefined && (
                <>
                  <p className={classes.paragraph}>
                    GivePower's new Solar Water Farm™ technology can deliver
                    clean, healthy drinking water to countless people in
                    critical need. In fact, a $20 donation can provide access to
                    clean water to someone in need for 20 years! And this Giving
                    Tuesday, your donation will have double the impact! On
                    December 3rd,{" "}
                    {partnerName === "Loanpal" && (
                      <span>donate to GivePower</span>
                    )}{" "}
                    {partnerName !== "Loanpal" && (
                      <span>
                        join{" "}
                        <span className="demi text-dark">{partnerName}</span>{" "}
                        and donate to the GivePower Foundation
                      </span>
                    )}{" "}
                    and Loanpal will generously match every donation made up to
                    a collective maximum of $100,000.
                  </p>
                  <p className={classes.paragraph}>
                    How many years of life-saving water are you ready to give
                    this Giving Tuesday?
                    <br />
                    Together, we can change thousands of lives!
                  </p>
                </>
              )}

            <a
              className="no-border html5lightbox match-video"
              data-autoplayvideo="true"
              data-fancybox="true"
              href="https://youtu.be/iddzhNnMkok?autoplay=1"
              data-width="960"
              data-height="540"
              samesite="none"
            >
              <figure className="image-over">
                <img
                  src={boysWater}
                  className={`${classes.imgFluid} ${classes.boys}`}
                  // className="img-fluid boy-water"
                  alt="GivePower Water"
                />
              </figure>
              {/* <div className="video-thumbnail" /> */}
              <div className="clearfix" />
            </a>

            {isBrowser && width >= 600 && (
              <div className={classes.quote}>
                "Contaminated water and water scarcity is killing more people on
                our planet than anything else. Using solar power and
                desalination technology, we have the ability to change that."
                <div className={classes.author}>
                  -Hayes Barnard, Founder, Chairman & CEO
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={5} id="donate" name="donate">
            {/* <div id="donate" name="donate" className="col-lg-5 col-md-12 p-3 donation-form"> */}
            {summary && (
              <ProgressBar
                progress={progress}
                goal={goal}
                progressBarColor="#103fa1"
                total_amount_in_cents={summary.total_amount_in_cents}
              />
            )}
            <Paper elevation={0} className={classes.paper}>
              <Box className={classes.donationForm}>
                <Box className={classes.secure}>
                  <FontAwesomeIcon icon={faLock} color="#103fa1" size="xs" />
                  <span className={classes.spaceText}>Secure Donation</span>
                </Box>
                {partnerId.toLowerCase() === "loanpal" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_d1d60b795594&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22Loanpal%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "flseia" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_d5c19a40f18b&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "trismart" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_21544a5c63e5&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "daybreaksolar" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_c9d22e2f35bb&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "nyelec" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_a0781019fc70&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "ourworldenergy" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_7bd5f131b040&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "solarray" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_0a93840517b0&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "skylinesolarpower" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_1f9fe3fbd407&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "harnesspower" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_afbdf148a3a0&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "pinegaterenewables" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_a2f5da9aff3f&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "expertsolarpros" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_0e5d79d4c0fc&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() ===
                  "bellsolarandelectricalsystems" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_a75da43aef6e&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%2353c0ea%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%2327264d%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "visionsolar" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_c867e07fc74d&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
                {partnerId.toLowerCase() === "nextdoorhomeupgrades" && (
                  <iframe
                    className={`donately-donation-iframe ${classes.donationFormIFrame}`}
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_981173706b26&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232e4cac%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%233355c1%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22FlaSEIA%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid container className={classes.fontMobile}>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paperCenter}>
              <h4 className="demi underline text-dark">The Problem:</h4>
            </Paper>
          </Grid>
          <Grid item xs={6} className={classes.marginTop4}>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paperCenter}>
                <h1 className={`heavy text-dark ${classes.fontH1}`}>
                  2 Billion
                </h1>
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.marginTop4}>
              <Paper elevation={0} className={classes.paperCenter}>
                <Box className="medium font-150 stats-box">
                  people face reduced access to freshwater resources.
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12} className={classes.marginTop4}>
              <Paper elevation={0} className={classes.paperCenter}>
                <h1 className={`heavy text-dark ${classes.fontH1}`}>10%</h1>
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.marginTop4}>
              <Paper elevation={0} className={classes.paperCenter}>
                <Box className="medium font-150 stats-box">
                  of the global population survives on less than $
                  <span className="heavy text-dark">1.90</span> per day.
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.marginTop4}>
            <Paper elevation={0} className={classes.paperCenter}>
              <h1 className={`heavy text-dark ${classes.fontH1}`}>$0.50</h1>
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.marginTop4}>
            <Paper elevation={0} className={classes.paperCenter}>
              <Box className="medium font-150 stats-box">
                is the approximate cost per liter for bottled drinking water.
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.marginTop6}>
            <Paper elevation={0} className={classes.paperCenter}>
              <Box className={classes.statsBoxLarge}>
                Because clean water is so expensive, people drink salty,
                disease-ridden water which can cause them to{" "}
                <span className="heavy text-dark">
                  die from completely preventable diseases.
                </span>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.marginTop6}>
            <Paper elevation={0} className={classes.paperCenter}>
              <Box className={classes.statsBoxLarge}>
                Washing their bodies and clothes in contaminated, brackish water{" "}
                <span className="heavy text-dark">causes open wounds</span> to
                fester and become infected.
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid container className={classes.divider}>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paperCenter}>
              <h4 className="demi underline text-dark">The Solution:</h4>
              <h4 className="demi text-dark">GivePower's Solar Water Farm™</h4>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.marginTop4}>
            <Paper elevation={0} className={classes.paperCenter}>
              <img
                src={desalUnit}
                className="img-fluid img-thumbnail image-settings"
                alt="..."
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.marginTop4}>
            <Paper elevation={0} className={classes.paperCenter}>
              <p className={classes.paragraphMedium}>
                GivePower's desalination unit runs on renewable energy and
                produces 75,000 liters of safe, clean water every day. Since our
                equipment is housed in shipping containers, we can easily build
                more containers and add them to existing units as demand for
                clean water grows.
              </p>
            </Paper>
          </Grid>
        </Grid>
        <Grid container className={classes.divider}>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paperCenter}>
              <h4 className="demi underline text-dark">The Impact:</h4>
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.marginTop4}>
            <Paper elevation={0} className={classes.paperCenter}>
              <img
                src={icons}
                className={`img-fluid img-thumbnail image-settings ${classes.imgSmall}`}
                alt="..."
              />
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.marginTop6}>
            <Paper elevation={0} className={classes.paperCenter}>
              <p className={classes.paragraphMediumItalic}>
                “When water comes from improved and more accessible sources,
                people spend less time and effort physically collecting it,
                meaning they can be productive in other ways. This can also
                result in greater personal safety by reducing the need to make
                long or risky journeys to collect water. Better water sources
                also mean less expenditure on health, as people are less likely
                to fall ill and incur medical costs, and are better able to
                remain economically productive.”
              </p>
              <p className="author-large ext-dark">
                -World Health Organization
              </p>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GivePartnersPage;
