import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { createMuiTheme, ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Lato',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1220,
      lg: 1440,
      xl: 1920
    },
  },
  palette: {
    primary: {
      main: '#00838f'
    },
    secondary: {
      main: '#F9AD1A',
      contrastText: '#ffcc00',
    },
    default: {
      main: '#9fa6b2'
    }
  },
})

const Main = ({ children }) => {
  return (
  <MUIThemeProvider theme={theme}>
    <React.StrictMode>
      <Router>
          {children}
      </Router>
    </React.StrictMode>
  </MUIThemeProvider>
  )
};

ReactDOM.render(
  <Main>
    <App />
  </Main>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

