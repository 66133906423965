import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textContainerMiddle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  mainFeaturedPost: {
    position: "relative",
    color: "#ffffff",
    backgroundColor: "#252f3f",
    backgroundSize: "cover",
    height: "650px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  mainFeaturedPostSmall: {
    position: "relative",
    color: "#ffffff",
    backgroundColor: "#252f3f",
    backgroundSize: "cover",
    height: "864px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0)",
    zIndex: 10,
  },
  mainFeaturedPostContent: {
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
      paddingRight: 0,
    },
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
    color: "#ffffff",
    zIndex: 100,
    position: "relative",
    textDecoration: "underline",
  },
  logo: {
    display: "flex",
    flexDirection: "inherit",
    justifyContent: "start",
    position: "relative",
    top: "25px",
    left: "25px",
    zIndex: 20,
  },
  logoImage: {
    height: "65px",
  },
  upperTextNoGutter: {
    color: "#ffffff",
    zIndex: 100,
    fontFamily: "futura-pt, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "1.7rem",
    letterSpacing: "1px",
    position: "relative",
    textAlign: "right",
    marginBottom: "0",
    width: "85%",
  },
  upperTextThinMiddle: {
    font: "normal normal 400 60px/65px futura-pt, sanserif",
    zIndex: 100,
    letterSpacing: "1px",
    width: "85%",
  },
  lowerTextBoldMiddle: {
    font: "normal normal 900 60px/65px futura-pt, sanserif",
    zIndex: 100,
    letterSpacing: "1px",
    width: "85%",
  },
  underTextBoldSmall: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    font: "normal normal 500 22px/24px futura-pt, sanserif",
    zIndex: 100,
    letterSpacing: "1px",
    width: "100%",
  },
  italic: {
    fontStyle: "italic",
  },
  buttonContainer: {
    zIndex: 100,
    position: "relative",
    textAlign: "left",
    "& a:link, a:visited": {
      color: "#ffffff",
      textDecoration: "none",
    },
  },
  button: {
    marginTop: theme.spacing(2),
    padding: "5px 40px",
    backgroundColor: "#e79913",
    color: "#ffffff !important",
    fontFamily: "futura-pt, sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#26cad3",
    },
    "& a": {
      textDecoration: "none",
    },
    "& a:hover": {
      textDecoration: "none",
    },
  },
}));

export default function StarHero({ data }) {
  const classes = useStyles();
  const post = {
    image: data.image,
    imageClass: data.imageClass,
    backgroundColor: data.backgroundColor,
    textContainer: data.textContainer,
    text: data.text,
    text2: data.text2,
    text3: data.text3,
    text4: data.text4,
    donateButton: data.donateButtonOn,
    textClass: data.textClass,
    text2Class: data.text2Class,
    text4Class: data.text4Class,
    columnLeft: data.columnLeft,
    columnRight: data.columnRight,
    contentAlign: data.align,
    top: data.top,
    font: data.font,
  };

  function createMarkup(html) {
    return { __html: html };
  }

  return (
    <Paper
      className={classes[post.imageClass]}
      style={{ backgroundImage: `url(${post.image})` }}
    >
      {/* Increase the priority of the hero background image */}
      {
        <img
          style={{ display: "none" }}
          src={post.image}
          alt={post.imageText}
        />
      }
      <div className={classes.overlay} />
      <Grid container className={classes[post.textContainer]}>
        <Grid item md={post.columnLeft}>
          {post.contentAlign === "left" && (
            <>
              <div
                className={classes.mainFeaturedPostContent}
                style={{ fontSize: post.font, top: post.top }}
              >
                <div
                  className={classes[post.text2Class]}
                  style={{ textAlign: "left" }}
                  color="inherit"
                >
                  <div dangerouslySetInnerHTML={createMarkup(post.text)} />
                </div>
                {post.text2 && (
                  <div
                    className={classes[post.textClass]}
                    style={{ textAlign: "left" }}
                    color="inherit"
                  >
                    <div dangerouslySetInnerHTML={createMarkup(post.text2)} />
                  </div>
                )}
                {post.text3 && (
                  <div
                    className={classes[post.text2Class]}
                    style={{ textAlign: "left" }}
                    color="inherit"
                  >
                    <div dangerouslySetInnerHTML={createMarkup(post.text3)} />
                  </div>
                )}
                {post.text4 && (
                  <div
                    className={classes[post.text4Class]}
                    style={{ textAlign: "left" }}
                    color="inherit"
                  >
                    <div dangerouslySetInnerHTML={createMarkup(post.text4)} />
                  </div>
                )}
                {post.donateButton && (
                  <div className={classes.buttonContainer}>
                    <Button
                      size="large"
                      className={classes.button}
                      href="/gt/GivePower"
                      value="Donate"
                    >
                      Donate
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </Grid>
        <Grid item md={post.columnRight}>
          {post.contentAlign === "right" && (
            <div
              className={classes.mainFeaturedPostContent}
              style={{ fontSize: post.font, top: post.top }}
            >
              <div
                className={classes[post.textClass]}
                color="inherit"
                gutterBottom
              >
                {post.text}
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

StarHero.propTypes = {
  post: PropTypes.object,
};
