import React from "react";
import { Paper, Grid, Box } from "@material-ui/core";

const MobiContainer = ({
  classes,
  isMobile,
  isBrowser,
  width,
  mobiImageMobile,
  mobiImage,
}) => {
  return (
    <Grid container className={classes.mobiContainer}>
      <Grid item xs={12}>
        <Paper elevation={0} className={classes.paperCenter}>
          <Grid container className={classes.mobiContainerInside}>
            {isMobile && (
              <Grid item md={6}>
                <Box>
                  <Box className={classes.mobiImageBox}>
                    <Box className={classes.bianchiShadow}>
                      <img
                        className={classes.mobiImage}
                        src={width < 640 ? mobiImageMobile : mobiImage}
                        alt="MOBI"
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item md={6}>
              <Box className={classes.mobiTextContainer}>
                <Box
                  className={`${classes.lead2Mobi} ${classes.greyText} ${classes.padding3}`}
                >
                  How many years of life-saving water are you ready to give this
                  Giving Tuesday?
                </Box>
                <Box
                  className={`${
                    isMobile ? classes.paragraph2Mobi : classes.subTitle
                  } ${classes.greyText} ${classes.padding3}`}
                >
                  Together, we can change thousands of lives!
                </Box>
                <Box
                  className={`${classes.paragraph2Mobi} ${classes.greyText}`}
                >
                  GivePower’s Solar Water Farm™ technology can deliver clean,
                  healthy drinking water to thousands in critical need. In fact,
                  a $20 donation can provide access to clean water to someone in
                  need for 20 years! And this Giving Tuesday, your donation can
                  have double the impact!
                </Box>
              </Box>
            </Grid>
            {isBrowser && (
              <Grid item md={6}>
                <Box>
                  <Box className={classes.mobiImageBox}>
                    <Box className={classes.bianchiShadow}>
                      <img className={classes.img} src={mobiImage} alt="MOBI" />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MobiContainer;
