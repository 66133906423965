import React, { useState, useEffect } from "react";
import Header from "components/shared/start-hero/header";
import Config from "utils/config";
import ProgressBar from "components/shared/progress-bar";
import StartHero from "components/shared/start-hero";
import StartHeroMobile from "components/shared/start-hero/mobile";
import { Paper, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import { isBrowser, isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  container: {
    width: "100% !important",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  lead: {
    textAlign: "left",
    font: "normal normal 600 28px/28px futura-pt, sanserif",
    color: "#000000",
    fontStyle: "italic",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 600 22px/20px futura-pt, sanserif",
    },
    [theme.breakpoints.up("sm")]: {
      font: "normal normal 600 28px/28px futura-pt, sanserif",
    },
    [theme.breakpoints.up("md")]: {
      font: "normal normal 600 28px/28px futura-pt, sanserif",
    },
  },
  paragraph: {
    font: "normal normal 500 22px/36px futura-pt, sanserif",
    textAlign: "justify !important",
    wordBreak: "keep-all",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 20px/25px futura-pt, sanserif",
    },
  },
  quote: {
    font: "normal normal 600 30px/55px futura-pt, sanserif",
    fontStyle: "oblique",
    color: "#000000",
    maxWidth: "680px",
    textAlign: "center",
    margin: "0 auto",
    marginTop: theme.spacing(5),
  },
  author: {
    font: "normal normal 600 20px/22px futura-pt, sanserif",
    fontStyle: "oblique",
    marginTop: theme.spacing(2),
  },
  secure: {
    color: "#f5831f",
    font: "normal normal 900 25px/25px futura-pt, sans-serif",
    margin: "25px 0",
  },
  spaceText: {
    marginLeft: "5px",
    position: "relative",
    top: "2px",
  },
  donationForm: {
    padding: "5px",
    backgroundColor: "#efefef",
    overflow: "hidden",
    scroll: "none",
  },
}));

const LoanpalDonatePage = () => {
  const classes = useStyles();
  const initialState = {
    width: window.innerWidth,
    id: "LoanpalMobi",
    list: null,
    summary: undefined,
    partner: Config.partners.filter((p) => p.id === "LoanpalMobi"),
    isBrowser: isBrowser,
    isMobile: isMobile,
  };

  useEffect(() => {
    async function onLoad() {
      console.log("Partner", partner[0]);
      getDonationsSummary(partner[0].campaignId);
      window.addEventListener("resize", handleWindowSizeChange);
    }
    onLoad();
    // eslint-disable-next-line
  }, []);

  const handleWindowSizeChange = () => {
    setState({ width: window.innerWidth });
  };
  const getDonationsSummary = (campaignId) => {
    fetch(
      `https://ctiag78qk2.execute-api.us-west-2.amazonaws.com/givepower/campaign/donations/${campaignId}`
    )
      .then((res) => res.json())
      .then((res) => setState({ summary: res }));
  };
  const [state, updateState] = useState(initialState);
  const setState = (newState) =>
    updateState((prevState) => ({ ...prevState, ...newState }));
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const { id: partnerId, summary, partner } = state;
  if (!partner)
    window.location.href = "https://donate.givepower.org/donate-now";
  if (!partner) return <></>;
  const logoImg = partner[0].icon;
  const logoSize = partner[0].size;
  const logoSizeM = partner[0].sizeM;
  const headerBg = partner[0].bg;
  // const gpLogo = partner[0].gpLogo;
  const partnerName = partner[0].name;
  const title = partner[0].title;
  const description = partner[0].description;
  const description2 = partner[0].description2;
  const gpLogoSize = partner[0].gpLogoSize;
  const gpLogoSizeM = partner[0].gpLogoSizeM;
  const progress = partner[0].progress;
  const goal = partner[0].goal;

  // const firstLine = "Let's bring water to Tierra Bomba, Colombia with our own GivePower Solar Water Farm Mobi";
  const firstLine = `Let's bring water to Tierra Bomba, Colombia with our own GivePower Solar Water Farm <i>Mobi</i>`;
  const bannerData = {
    id: 0,
    image:
      "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/givepower/donate/mobi-banner-donatepage-min.jpg",
    imageClass: "mainFeaturedPost",
    backgroundColor: "#ffffff",
    text: firstLine,
    textContainer: "textContainerTop",
    textClass: "upperTextNoGutter",
    columnLeft: 6,
    columnRight: 6,
    align: "left",
    menu: false,
    top: 0,
  };

  const bannerDataMobile = {
    id: 0,
    imageMobile:
      "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1600390850/givepower/donate/mobi-banner-mobile-min.jpg",
    backgroundColor: "#ffffff",
    imageClass: "mainFeaturedPostContain",
    text: firstLine,
    textClassM: "whiteTextMargin",
    columnLeft: 10,
    columnRight: 2,
    align: "left",
    menu: true,
    topM: "6%",
  };

  return (
    <div
      style={{
        maxWidth: "1920px",
        margin: "0 auto",
        backgroundColor: "#ffffff",
        width: "108%",
        borderRight: "0.5px solid #fff4eb",
        borderLeft: "0.5px solid #fff4eb",
      }}
    >
      <Header
        image="img-container-hero-water"
        signup={false}
        float=""
        logoImg={logoImg}
        logoSize={logoSize}
        logoSizeM={logoSizeM}
        headerBg={headerBg}
        partnerName={partnerName}
        gpLogoSize={gpLogoSize}
        gpLogoSizeM={gpLogoSizeM}
        display={true}
      />
      {isMobile && <StartHeroMobile data={bannerDataMobile} />}
      {isBrowser && width >= 600 && <StartHero data={bannerData} />}
      <div className={classes.root}>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12} sm={7}>
            <p className={classes.lead}>{title}</p>
            <p className={classes.paragraph}>{description}</p>
            <p className={classes.paragraph}>{description2}</p>
            {isBrowser && width >= 600 && (
              <div className={classes.quote}>
                "Contaminated water and water scarcity is killing more people on
                our planet than anything else. Using solar power and
                desalination technology, we have the ability to change that."
                <div className={classes.author}>
                  -Hayes Barnard, Founder, Chairman & CEO
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={5} id="donate" name="donate">
            {/* <div id="donate" name="donate" className="col-lg-5 col-md-12 p-3 donation-form"> */}
            {
              summary && (
                <ProgressBar
                  progress={progress}
                  goal={goal}
                  progressBarColor="#f5831f"
                  total_amount_in_cents={
                    summary.total_amount_in_cents + 7964207 // 7170087 + 794120 = 7964207
                  }
                />
              )
              /* 3875000 + 625000 = 4500000 */
            }
            <Paper elevation={0} className={classes.paper}>
              <Box className={classes.donationForm}>
                <Box className={classes.secure}>
                  <FontAwesomeIcon icon={faLock} color="#f5831f" size="xs" />
                  <span className={classes.spaceText}>Secure Donation</span>
                </Box>
                {partnerId.toLowerCase() === "loanpalmobi" && (
                  <iframe
                    className="donately-donation-iframe"
                    frameBorder="0"
                    allowtransparency="true"
                    title="Join the Charge"
                    style={{
                      backgroundColor: "transparent",
                      border: "0px none transparent",
                      overflow: "hidden",
                      display: "inlineBlock",
                      visibility: "visible",
                      margin: "0px",
                      padding: "0px",
                      height: "915px",
                      width: "100%",
                    }}
                    src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_d6b0b0246254&amp;stripe_publishable_key=pk_live_IRMEinbjKnY5R76XR8sQ2qtZ&amp;donately_ga_account=UA-123029666-1&amp;donately_amount=60&amp;donately_campaign_id=cmp_15412b27e2f4&amp;donately_comment=true&amp;donately_custom_css=%7B%22.donately-donation-form%20.donately-btn%22:%7B%22width%22:%22100%25%22%7D%2C%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%23e79913%22%7D%2C%20%22.donately-donation-form%20.donately-btn%3Ahover%22:%7B%22background-color%22:%22%2326cad3%22%7D%7D&amp;donately_custom_fields=%7B%22fields%22%3A%20%5B%7B%20%22type%22%3A%22hidden%22%2C%20%22id%22%3A%22partner%2C%20%22value%22%3A%22Loanpal%22%20%7D%5D%20%7D&&amp;donately_donor_pays_fees=%7B%22cc%22:%7B%22processor_percent%22:%220.029%22,%22processor_fixed%22:%220.30%22,%22dntly_percent%22:%220.03%22%7D%7D&amp;donately_presets=20,40,60,100,200"
                  />
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
      {/* <footer>
         <div className={classes.logo}>
             <a className={classes.logoLink} href="https://givepower.org">
                 <img className={classes.logoImage} src="https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,f_auto,dpr_auto,c_scale/v1/givepower/gp-yellow-tm-vertical.png" alt="GivePower" />
             </a>
          </div>`
          <div className={classes.copyright}>
            &copy; 2020 GivePower. All Rights Reserved.
          </div>
        </footer> */}
    </div>
  );
};
export default LoanpalDonatePage;
