import React from "react";
import './styles.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    footerSpace: {
      paddingTop: '100px !important',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '10px !important',
      },
    },
    clearfix: {
    '&:after': {
        content: "",
        clear: 'both',
        display: 'table'
        }
    },
    fwCopyright: {
        color: '#b7bfc2',
        font: 'normal normal 500 20px/23px lato, sanserif',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            font: 'normal normal 500 14px/18px lato, sanserif',
        },
        [theme.breakpoints.up('sm')]: {
            font: 'normal normal 500 18px/20px lato, sanserif',
        },
        [theme.breakpoints.up('md')]: {
            font: 'normal normal 500 20px/23px lato, sanserif',
        }
    }
}));

const Footer = ({ pathname }) => {
  const classes = useStyles();

  return(
  <div className={classes.clearfix}>
    <footer id="colophon" className={`site-footer fw-footer ${pathname === '/' ? 'fw-footer-root' : 'fw-footer-root-small'}`} itemScope="itemScope" itemType="https://schema.org/WPFooter">
		<div className={`${classes.footerSpace} fw-footer-widgets footer-cols-1`}>
			<div className="fw-inner">
			    <div className="fw-container">
				    <div className="fw-row">
						<div className="fw-col-md-12">
							<aside id="custom_html-5" className="widget_text widget widget_custom_html">
                                <div className="textwidget custom-html-widget">
                                    <div style={{color: "#000000", fontSize: "120%"}} className="fw-footer-middle">
                                        <div className="fw-container">
                                            <nav id="fw-footer-menu" className="fw-footer-menu">
                                                <ul id="menu-who-we-are" className="">
                                                    <li id="menu-item-1202" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1202"><a href="https://givepower.org/" hidefocus="true" style={{outline: 'none'}}><span>What We Do</span></a></li>
                                                    <li id="menu-item-1203" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1203"><a href="https://givepower.org/projects/" hidefocus="true" style={{outline: 'none'}}><span>Projects</span></a></li>
                                                    <li id="menu-item-1204" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1204"><a href="https://givepower.org/get-involved/" hidefocus="true" style={{outline: 'none'}}><span>Partner With Us</span></a></li>
                                                    <li id="menu-item-935" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-935"><a href="https://givepower.org/contact/" hidefocus="true" style={{outline: 'none'}}><span>Contact</span></a></li>
                                                    <li id="menu-item-858" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-858"><a href="https://jobs.jobvite.com/givepower" hidefocus="true" target="_blank" style={{outline: 'none'}} rel="noopener noreferrer"><span>Careers</span></a></li>
                                                    <li id="menu-item-2120" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2120"><a href="https://givepower.org/financials/" hidefocus="true" style={{outline: 'none'}}><span>Financials</span></a></li>
                                                    <li id="menu-item-1205" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1205"><a href="https://donate.givepower.org/" hidefocus="true" style={{outline: 'none'}}><span><span style={{color: '#ffd02d'}}>Donate</span></span></a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="footer-custom">
                                        <div className="footer-seal" style={{padding:'20px 0', width:'375px', margin:'0 auto'}}>
                                            <img src="https://givepower.org/wp-content/uploads/2020/01/guideStarSeal_2019_gold.jpg" alt="Seal of Transparency 2019 Gold" style={{width:'350px'}} />
                                        </div>
                                        <div style={{padding: '10px 25px', color:'#000000', fontSize:'28px', width: '375px', margin:'0 auto'}}>
                                            #jointhecharge
                                        </div>
                                        <div style={{width: '375px', paddingTop: '25px', margin:'0 auto'}}>
                                            <a href="https://www.instagram.com/givepowerfoundation/" hidefocus="true" style={{outline: 'none'}}><img alt="instagram" style={{marginRight: '10px'}} src="https://givepower.org/wp-content/uploads/2018/09/glyph-logo_28x28.png" /></a>
                                            <a href="https://www.facebook.com/givepowerfoundation/" hidefocus="true" style={{outline: 'none'}}><img alt="facebook" style={{marginRight: '10px'}} src="https://givepower.org/wp-content/uploads/2016/03/footer-social-facebook.png" /></a>
                                            <a href="https://www.youtube.com/channel/UCugFP89mcTrJcqP5LTg8VnQ" hidefocus="true" style={{outline: 'none'}}><img alt="youtube" style={{marginRight: '10px'}} src="https://givepower.org/wp-content/uploads/2018/09/youtube_social_icon_dark.png" /></a>
                                            <a href="https://vimeo.com/givepowerfoundation" hidefocus="true" style={{outline: 'none'}}><img alt="vimeo" style={{marginRight: '10px'}} src="https://givepower.org/wp-content/uploads/2016/03/footer-social-vimeo.png" /></a>
                                            <a href="https://www.linkedin.com/company/givepower/" hidefocus="true" style={{outline: 'none'}}><img alt="linkedin" style={{marginRight: '10px'}} src="https://givepower.org/wp-content/uploads/2018/09/In-Black-28px-R.png" /></a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
		            </div>
	            </div>
            </div>
        </div>
		<div className="fw-footer-middle  ">
		    <div className="fw-container">
			</div>
	    </div>
		<div className="fw-footer-bar fw-copyright-center">
			<div className="fw-container">
				<div className={classes.fwCopyright}> 2020 GivePower Foundation | 501(c)(3) EIN: #47-1265705 | All donations tax-deductible where allowed by law</div>
			</div>
		</div>
	</footer>
  </div>
  )
}

export default Footer;
