module.exports = {
  default: "hsanchez@loanpal.com",
  partners: [
    {
      id: "GivePower",
      partnerId: "GivePower",
      icon: "",
      size: "250px",
      sizeM: "140px",
      email: "hsanchez@loanpal.com",

      name: "GivePower",
      progress: false,
      goalOn: false,
      goal: 10000,
      offset: 0,
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "80px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_15e3cbe18911",
    },
    {
      id: "Loanpal",
      partnerId: "Loanpal",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/loanpal.com/lpsmile_white_orange_o-R.11c11dde.svg",
      size: "250px",
      sizeM: "140px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goalOn: false,
      goal: 10000,
      offset: 0,
      name: "Loanpal",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "60px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_d1d60b795594",
      title: "BE THE POWER FOR CHANGE",
    },
    {
      id: "Titan080",
      partnerId: "TitanSolarPower",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/Titan_Solar_Power.png",
      size: "295px",
      sizeM: "140px",
      email: "hsanchez@loanpal.com",
      // email: 'sjones@titansolarpower.com',
      name: "Titan Solar Power",
      progress: false,
      goalOn: false,
      goal: 10000,
      offset: 12157,
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "80px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_47ffb656a01e",
    },
    {
      id: "Empire070",
      partnerId: "EmpireSolar",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/empiresolar-icon.png",
      size: "150px",
      sizeM: "65px",
      email: "hsanchez@loanpal.com",
      // email: 'info@empiresolargroup.com',
      progress: false,
      goal: 10000,
      goalOn: false,
      offset: 78609,
      name: "Empire Solar Energy",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "80px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_0703380ac158",
    },
    {
      id: "Dynamic090",
      partnerId: "DynamicSLR",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/Dynamic-SLR-Logo.png",
      size: "350px",
      sizeM: "165px",
      email: "hsanchez@loanpal.com",
      // email: '',
      progress: false,
      goal: 10000,
      goalOn: false,
      offset: 52440,
      name: "Dynamic SLR",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "55px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_b045f9afaed5",
    },
    {
      id: "BlueRaven060",
      partnerId: "BlueRaven",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/blueraven-icon.png",
      size: "300px",
      sizeM: "165px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 10000,
      goalOn: false,
      offset: 22444,
      // email: 'marketing@blueravensolar.com',
      name: "Blue Raven",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "55px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_9b85eea194f1",
    },
    {
      id: "OurWorldEnergy050",
      partnerId: "OurWorldEnergy",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/ourworldenergy.png",
      size: "230px",
      sizeM: "150px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      goalOn: false,
      offset: 0,
      // email: 'cantonucci@ourworldenergy.com',
      name: "Our World Energy",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "55px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_874f3041e4db",
      title: "BE THE POWER FOR CHANGE",
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Our World Energy has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Our World Energy is proud to make a $40 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "Skyline0110",
      partnerId: "SkylineSolarPower",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1605809689/partners/originals/skylineSolar_1.png",
      size: "320px",
      sizeM: "190px",
      // email: 'hsanchez@loanpal.com',
      progress: false,
      goal: 100000,
      goalOn: false,
      offset: 0,
      name: "Skyline Solar Power",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_8fe49cd2e981",
      title: "BE THE POWER FOR CHANGE",
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Skyline Solar Power has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Skyline Solar Power is proud to make a $20 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "ExpertSolarPros020",
      partnerId: "ExpertSolarPros",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/ExpertSolarPros.png",
      size: "250px",
      sizeM: "145px",
      // email: 'hsanchez@loanpal.com',
      progress: false,
      goal: 100000,
      goalOn: false,
      offset: 0,
      name: "Expert Solar Pros",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_33f480d0d8cc",
      title: "BE THE POWER FOR CHANGE",
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Expert Solar Pros has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Expert Solar Pros is proud to make a $20 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "BellElectricalSystems040",
      partnerId: "BellSolarAndElectricalSystems",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/BellSolarElectrical.png",
      size: "290px",
      sizeM: "165px",
      // email: 'hsanchez@loanpal.com',
      progress: false,
      goal: 100000,
      goalOn: false,
      offset: 0,
      name: "Bell Solar and Electrical Systems",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_acb07c7dca90",
      donateButton:
        "../../../resources/css/img/match/button-active-bell-min.png",
      primaryColor: "#53c0ea",
      secondaryColor: "#27264d",
      title: "BE THE POWER FOR CHANGE",
    },
    {
      id: "Sunworks010",
      partnerId: "Sunworks",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/Sunworks.png",
      size: "290px",
      sizeM: "145px",
      // email: 'hsanchez@loanpal.com',
      progress: false,
      goal: 100000,
      goalOn: false,
      offset: 0,
      name: "Sunworks",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_9fa009786ac3",
      title: "BE THE POWER FOR CHANGE",
    },
    {
      id: "NewPower030",
      partnerId: "NewPower",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1605810235/partners/originals/NewPower2.png",
      size: "335px",
      sizeM: "185px",
      // email: 'hsanchez@loanpal.com',
      progress: false,
      goal: 100000,
      goalOn: false,
      offset: 0,
      name: "New Power",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_c59b8d724110",
      title: "BE THE POWER FOR CHANGE",
    },
    {
      id: "TexasSolarIntegrated0220",
      partnerId: "TexasSolarIntegrated",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/TexasSolarIntegrated.png",
      size: "320px",
      sizeM: "170px",
      // email: 'hsanchez@loanpal.com',
      progress: false,
      goal: 100000,
      goalOn: false,
      offset: 0,
      name: "Texas Solar Integrated",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "36px",
      campaignId: "cmp_db1eecf975e9",
      title: "BE THE POWER FOR CHANGE",
    },
  ],
};
