import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
        position: 'relative',
        color: '#ffffff',
        backgroundColor: "#252f3f",
        backgroundSize: 'cover',
        height: '535px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    mainFeaturedPostSmall: {
        position: 'relative',
        color: '#ffffff',
        backgroundColor: "#252f3f",
        backgroundSize: 'cover',
        height: '650px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    mainFeaturedPostContain: {
        position: 'relative',
        color: '#ffffff',
        backgroundColor: "#252f3f",
        backgroundSize: 'cover',
        height: '445px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    textContainerMiddle: {
        paddingTop: '40px'
    },
    text2Class: {
        font: 'normal normal 700 50px/60px lato, sanserif',
        color: '#FAD12E',
        textAlign: 'center',
        width: '50%',
        margin: '0 auto'
    },
    text1Class: {
        font: 'normal normal 900 150px/180px lato, sanserif',
        color: '#FAD12E',
        textAlign: 'center',
        width: '100%'
    }
}));

export default function StarHero({ data }) {
  const classes = useStyles();
  const post  = {
      image: data.image,
      imageClass: data.imageClass,
      backgroundColor: data.backgroundColor,
      textContainer: data.textContainer,
      text: data.text,
      text2: data.text2,
      textClass: data.textClass,
      text2Class: data.text2Class,
      columnLeft: data.columnLeft,
      columnRight: data.columnRight,
      contentAlign: data.align,
  };
  console.log("in Star Hero", post);
  return (
    <Paper elevation={0} className={classes[post.imageClass]} style={{ backgroundImage: `url(${post.image})` }}>
      {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}
      <div className={classes.overlay} />
      <Grid container className={classes[post.textContainer]}>
        <Grid item md={post.columnLeft}>
          <>
          </>
        </Grid>
        <Grid item md={post.columnRight}>
           <>
           </>
        </Grid>
      </Grid>
    </Paper>
  );
}

StarHero.propTypes = {
  post: PropTypes.object,
};