import React from "react";
import { Paper, Grid, Box } from "@material-ui/core";

const GlassContainer = ({
  classes,
  isMobile,
  isBrowser,
  width,
  glassWaterMobile,
  glassWater,
}) => {
  return (
    <Grid container className={classes.glassContainerTop}>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          className={classes.glassFeature}
          style={{
            backgroundImage: `url(${isMobile ? glassWaterMobile : glassWater})`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={isMobile && width < 640 ? glassWaterMobile : glassWater}
              alt="Fresh drinking water"
            />
          }
          {(isMobile || (isBrowser && width < 1220)) && (
            <Grid container className={classes.glassContainerGrid}>
              <Grid item xs={7} sm={6} md={6}>
                <Box className={classes.glassTextContainer}>
                  <Box className={`${classes.lead2} ${classes.greyText}`}>
                    Let’s take a moment to think about
                  </Box>
                  <Box className={classes.leadHeavy}>FRESH DRINKING</Box>
                  <Box className={classes.waterText}>WATER.</Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={5}
                sm={6}
                md={6}
                className={classes.glassTextRight}
              >
                <></>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className={classes.underWater}>
                  <Box
                    className={`${classes.paragraph2} ${classes.paragraph2StepA}`}
                  >
                    Visualize that sip, or even a large gulp that quenches a
                    sudden thirst. Really, think about it. When’s the last time
                    you had a sip?
                  </Box>
                  <Box
                    className={`${classes.paragraph2} ${classes.paragraph2StepB}`}
                  >
                    Don’t reach for your water just yet.
                  </Box>
                  <Box
                    className={`${classes.paragraph2} ${classes.paragraph2StepC}`}
                  >
                    The more you think on it, the thirstier you’ll likely
                    become. Now imagine knowing that you won’t have any water –
                    even a sip – for the next 24 hours. It may not be
                    life-threatening, but it is an uncomfortable, even
                    unhealthy, proposition.
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <></>
              </Grid>
            </Grid>
          )}
          {isBrowser && width >= 1220 && (
            <Grid container className={classes.glassContainerGrid}>
              <Grid item md={5}>
                <></>
              </Grid>
              <Grid item md={7}>
                <Box className={classes.glassTextContainer}>
                  <Box className={`${classes.lead2} ${classes.greyText}`}>
                    Let’s take a moment to think about
                  </Box>
                  <Box className={classes.leadHeavy}>FRESH DRINKING WATER.</Box>
                  <Box className={`${classes.paragraph2}`}>
                    Visualize that sip, or even a large gulp that quenches a
                    sudden thirst. Really, think about it. When’s the last time
                    you had a sip?
                  </Box>
                  <Box className={`${classes.paragraph2}`}>
                    Don’t reach for your water just yet.
                  </Box>
                  <Box className={`${classes.paragraph2}`}>
                    The more you think on it, the thirstier you’ll likely
                    become. Now imagine knowing that you won’t have any water –
                    even a sip – for the next 24 hours. It may not be
                    life-threatening, but it is an uncomfortable, even
                    unhealthy, proposition.
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GlassContainer;
