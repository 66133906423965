import React from "react";
import { isMobile } from "react-device-detect";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import gpLogoWhite from "components/shared/images/givepower_TM_logo_Horizontal_White.svg";
import gpLogoBlack from "components/shared/images/givepower_TM_logo_Horizontal_Black.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 0",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "5px 0",
    },
    [theme.breakpoints.up("md")]: {
      padding: "5px 0",
    },
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  paddingLogoLeft: {
    paddingRight: "30px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingRight: "10px",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "30px",
    },
  },
  paddingLogoRight: {
    paddingLeft: "30px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "10px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
    },
  },
  marginGrid: {
    margin: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Header = ({
  logoImg,
  logoSize,
  logoSizeM,
  headerBg,
  gpLogoSize,
  gpLogoSizeM,
  partnerName,
  display,
  float,
  width,
}) => {
  const classes = useStyles();

  return (
    <header
      className={`${classes.root} ${display ? classes.show : classes.hide}`}
    >
      <nav className={headerBg} style={{ maginTop: "12px" }}>
        <Grid container spacing={6} className={classes.container}>
          <Grid item xs={12} className={classes.marginGrid}>
            <a href="https://givepower.org" style={{ borderBottom: "none" }}>
              <img
                style={{ height: isMobile ? gpLogoSizeM : gpLogoSize }}
                className={`logo ${float} ${classes.paddingLogoLeft}`}
                src={`${headerBg === "bg-white" ? gpLogoBlack : gpLogoWhite}`}
                alt="GivePower"
              />
            </a>
            {logoImg && (
              <img
                className={`${classes.paddingLogoRight}`}
                src={logoImg}
                style={{ width: isMobile ? logoSizeM : logoSize }}
                alt={partnerName}
              />
            )}
          </Grid>
        </Grid>
      </nav>
    </header>
  );
};

export default Header;
