module.exports = {
  default: "hsanchez@loanpal.com",
  partners: [
    {
      id: "Loanpal",
      partnerId: "Loanpal",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/loanpal.com/lpsmile_white_orange_o-R.11c11dde.svg",
      size: "250px",
      sizeM: "155px",
      email: "hsanchez@loanpal.com",
      progress: true,
      goal: 10000,
      name: "Loanpal",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      donateButtonOn: false,
      gpLogoSize: "60px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_d1d60b795594",
      title: "BE THE POWER FOR CHANGE",
    },
    {
      id: "LoanpalMobi",
      partnerId: "LoanpalMobi",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/loanpal.com/lpsmile_white_orange_o-R.11c11dde.svg",
      size: "250px",
      sizeM: "147px",
      email: "hsanchez@loanpal.com",
      progress: true,
      goal: 120000,
      name: "Loanpal",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "60px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_15412b27e2f4",
      donateButtonOn: false,
      title: "THE CHALLENGE",
      description:
        "From now until the end of the year, our challenge is to raise $120,000 from Loanpal employees and fund a GivePower Solar Water Farm Mobi. The Mobi is more compact than its larger counterpart, the Max, and designed for more rapid deployment. This new system allows GivePower to generate clean water within a week of the equipment arriving in a new location. Each Mobi can provide up to 6,000 liters of fresh water daily. That’s fresh drinking water for as many as 2,500 people every single day for the next 20 years!",
      description2: "",
      description3: "",
    },
    {
      id: "FLSEIA",
      partnerId: "Flaseia",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/Flaseia-logo.png",
      size: "250px",
      sizeM: "150px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 10000,
      name: "FlaSEIA",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      donateButtonOn: false,
      gpLogoSize: "55px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_d5c19a40f18b",
    },
    {
      id: "Trismart",
      partnerId: "Trismart",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/trismart-white.png",
      size: "250px",
      sizeM: "150px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 10000,
      name: "Trismart Solar",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "55px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_21544a5c63e5",
      title: "BE THE POWER FOR CHANGE",
      donateButtonOn: false,
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, TriSMART Solar has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "TriSMART is proud to make a $40 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "NYELEC",
      partnerId: "NYELEC",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1590614031/partners/originals/NYELEC-transparent.png",
      size: "180px",
      sizeM: "108px",
      email: "hsanchez@loanpal.com",
      // email: 'bill.nyelec@gmail.com',
      name: "NYELEC",
      progress: true,
      goal: 100000,
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "55px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_a0781019fc70",
      title: "BE THE POWER FOR CHANGE",
      donateButtonOn: false,
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, NYELEC has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "NYELEC is proud to make a donation of 3% of the each completed transaction to GivePower. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "OurWorldEnergy",
      partnerId: "OurWorldEnergy",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1589302630/partners/originals/ourworldenergy.png",
      size: "180px",
      sizeM: "108px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      // email: 'cantonucci@ourworldenergy.com',
      name: "Our World Energy",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "55px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_7bd5f131b040",
      title: "BE THE POWER FOR CHANGE",
      donateButtonOn: false,
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Our World Energy has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Our World Energy is proud to make a $40 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "DaybreakSolar",
      partnerId: "DaybreakSolar",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1589302630/partners/originals/daybreak-solar.png",
      size: "180px",
      sizeM: "108px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 10000,
      name: "Daybreak Solar",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "55px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_c9d22e2f35bb",
      title: "BE THE POWER FOR CHANGE",
      donateButtonOn: false,
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Daybreak Solar Power has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Daybreak Solar Power is proud to make a $100 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "SolarRay",
      partnerId: "SolarRay",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1589302630/partners/originals/solar-ray.png",
      size: "180px",
      sizeM: "108px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      name: "Solar Ray",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "75px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_0a93840517b0",
      title: "BE THE POWER FOR CHANGE",
      donateButtonOn: false,
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Solar Ray has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Solar Ray is proud to make a $40 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "SkylineSolarPower",
      partnerId: "SkylineSolarPower",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1589302630/partners/originals/skylineSolar_1.png",
      size: "320px",
      sizeM: "192px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      name: "Skyline Solar Power",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_1f9fe3fbd407",
      title: "BE THE POWER FOR CHANGE",
      donateButtonOn: false,
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Skyline Solar Power has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Skyline Solar Power is proud to make a $20 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "HarnessPower",
      partnerId: "HarnessPower",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/harness-power.png",
      size: "320px",
      sizeM: "192px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      name: "Harness Power",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_afbdf148a3a0",
      title: "BE THE POWER FOR CHANGE",
      donateButtonOn: false,
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Harness Power has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Harness Power is proud to make a contribution for every installation complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "PineGateRenewables",
      partnerId: "PineGateRenewables",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/v1603472774/partners/originals/PineGateRenewables_logo_white-o.png",
      size: "280px",
      sizeM: "165px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      name: "Pine Gate Renewables",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_a2f5da9aff3f",
      title: "BE THE POWER FOR CHANGE",
      donateButtonOn: false,
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Pine Gate Renewables has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Pine Gate Renewables is proud to make a donation of $200/megawatt DC solar install to GivePower. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "ExpertSolarPros",
      partnerId: "ExpertSolarPros",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/w_auto,q_auto,dpr_auto,c_scale/v1/partners/originals/ExpertSolarPros.png",
      size: "250px",
      sizeM: "150px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      name: "Expert Solar Pros",
      bg: "bg-inverse",
      gpLogo: "givepower_TM_logo_Horizontal_White.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_0e5d79d4c0fc",
      title: "BE THE POWER FOR CHANGE",
      donateButtonOn: false,
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Expert Solar Pros has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Expert Solar Pros is proud to make a $20 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "BellSolarAndElectricalSystems",
      partnerId: "BellSolarAndElectricalSystems",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/v1604422020/partners/originals/BellSolarElectrical.png",
      size: "250px",
      sizeM: "150px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      name: "Bell Solar and Electrical Systems",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_a75da43aef6e",
      donateButtonOn: false,
      donateButton:
        "../../../resources/css/img/match/button-active-bell-min.png",
      title: "BE THE POWER FOR CHANGE",
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Bell Solar and Electrical Systems has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Bell Solar and Electrical Systems is proud to make a $80 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "VisionSolar",
      partnerId: "VisionSolar",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/v1604422020/partners/originals/vision-solar.png",
      size: "210px",
      sizeM: "120px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      name: "Vision Solar",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_c867e07fc74d",
      donateButtonOn: false,
      donateButton:
        "../../../resources/css/img/match/button-active-bell-min.png",
      primaryColor: "#53c0ea",
      secondaryColor: "#27264d",
      title: "BE THE POWER FOR CHANGE",
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Vision Solar has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Vision Solar is proud to make a $40 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
    {
      id: "NextDoorHomeUpgrades",
      partnerId: "NextDoorHomeUpgrades",
      icon:
        "https://res.cloudinary.com/loanpal/image/upload/v1617292909/partners/originals/NextDoorLogo.png",
      size: "210px",
      sizeM: "120px",
      email: "hsanchez@loanpal.com",
      progress: false,
      goal: 100000,
      name: "Next Door Home Upgrades",
      bg: "bg-white",
      gpLogo: "givepower_TM_logo_Horizontal_Black.svg",
      gpLogoSize: "68px",
      gpLogoSizeM: "38px",
      campaignId: "cmp_981173706b26",
      donateButtonOn: false,
      donateButton:
        "../../../resources/css/img/match/button-active-bell-min.png",
      primaryColor: "#53c0ea",
      secondaryColor: "#27264d",
      title: "BE THE POWER FOR CHANGE",
      description:
        "GivePower provides solar-based solutions that power life's basic needs for people in developing regions of the world. Waterborne disease is the planet's leading killer. It's no surprise when you consider that three out of ten people, worldwide, lack access to a safe, reliable source of water in their home. But, there is a lot that we can do to help.",
      description2:
        "In partnership with GivePower, Next Door Home Upgrades has the opportunity to make a difference in tens of thousands of lives. GivePower has developed an innovative solar-powered desalination system that is sustainable and highly cost-effective. In fact, a simple $20 investment can give one person access to safe drinking water for at least 20 years.",
      description3:
        "Next Door Home Upgrades is proud to make a $40 donation to GivePower for every solar installation we complete. As a company, we have always been committed to making the planet a healthier, safer place for people. Let's continue to be the power for change! Join the charge!",
    },
  ],
};
