import React from "react";
import { Grid, Box } from "@material-ui/core";

const VideoContainer = ({ classes, boysWater, isMobile, isBrowser }) => {
  return (
    <Grid container className={classes.videoContainer}>
      <Grid item md={6} className={classes.videoGrid}>
        <Box className={classes.videoBox}>
          <a
            className="no-border html5lightbox match-video"
            data-autoplayvideo="true"
            data-fancybox="true"
            href="https://youtu.be/AQRezdh9fzk?autoplay=1"
            data-width="960"
            data-height="540"
            samesite="none"
          >
            <figure style={{ margin: "0" }} className="image-over">
              <img
                src={boysWater}
                className={`${classes.imgFluid} ${classes.boys}`}
                // className="img-fluid boy-water"
                alt="GivePower Water"
              />
            </figure>
            <div className="clearfix" />
          </a>
        </Box>
      </Grid>
      <Grid item md={6} className={classes.textGrid}>
        <Box className={classes.textBox}>
          <Box className={`${classes.lead} ${classes.whiteText}`}>
            See how the GivePower Solar Water Farm™ has transformed the
            community of Likoni.
          </Box>

          <Box className={`${classes.paragraphSmall} ${classes.whiteText}`}>
            For the people of Likoni, Kenya, a small community on the outskirts
            of Mombasa, finding accessible water is a daily struggle, and
            purchasing water is largely cost-prohibitive for most in this
            low-income area. As a result, people often drink salty,
            bacteria-ridden water which can cause severe illness and even death.
            In June 2020, GivePower’s Solar Water Farm Max went live in
            Likoni. For the first time, this community has access to clean,
            healthy water for their families. The Max system can produce up to
            70,000 liters of fresh water and provide access for up to 35,000
            people every single day.
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoContainer;
