import React, { useRef } from 'react';
import Views from './components/views';
import { useHistory } from 'react-router-dom';
import './App.css';

const App = () => {
  const viewContainerRef = useRef();
  const history = useHistory();
  const location = history.location;
  const { pathname } = location;
  const pathnameNoId = pathname.substring(0, pathname.lastIndexOf('/'));
  return (
    <div className={`${(pathnameNoId === '/' || pathnameNoId === '/donate-now') ? 'site-root' : 'site-root site-root-small'}`}>
      <Views { ... { location, history, viewContainerRef }} />
    </div>
  );
}

export default App;
