import React from 'react';
import './styles.css';
import {
  isMobile,
  isBrowser
} from "react-device-detect";
import { Grid }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import gpLogoWhite from 'components/shared/images/givepower_TM_logo_Horizontal_White.svg';
import gpLogoBlack from 'components/shared/images/givepower_TM_logo_Horizontal_Black.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 0',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '5px 0',
    },
    [theme.breakpoints.up('md')]: {
      padding: '5px 0',
    }
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  paddingLogoLeft: {
    paddingRight: '30px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '10px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '30px',
    }
  },
  paddingLogoRight: {
    paddingLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5px',
      paddingBottom: '10px'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '30px',
    }
  },
  marginGrid: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const Header = ({ pathname }) => {
  const classes = useStyles();
  const headerBg = 'bg-inverse';
  const gpLogoSize = '55px';
  const gpLogoSizeM = '55px';
  const display = true;
  const float = ""


  return (
    <>
    {isMobile &&
      <header className={`${classes.root} ${display ? classes.show : classes.hide}`}>
        <nav className={headerBg} style={{maginTop: '12px'}}>
          <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.marginGrid}>
              <a href="https://givepower.org" style={{ borderBottom: 'none' }}>
                <img
                  style={{height: isMobile ? gpLogoSizeM : gpLogoSize}}
                  className={`logo ${float} ${classes.paddingLogoLeft}`}
                  src={`${headerBg === 'bg-white' ? gpLogoBlack : gpLogoWhite}`}
                  alt="GivePower"
                />
              </a>
            </Grid>
          </Grid>
        </nav>
      </header>
    }
    {isBrowser &&
    <header className={`fw-header ${pathname === '' ? 'fw-header-non-transparent' : 'fw-header-small fw-header-non-transparent'}`} itemScope="itemScope" itemType="https://schema.org/WPHeader">
      <div className="fw-header-main">
        <div className="fw-container">
          <a href="#mobile-menu" className="mmenu-link" hidefocus="true" style={{outline: 'none'}}><i className="fa fa-navicon"></i></a>
          <div className="fw-wrap-logo">
              <a href="https://givepower.org/" className="fw-site-logo" hidefocus="true" style={{outline: 'none'}}>
                <img src="//givepower.org/wp-content/uploads/2020/05/GP_TM_BLACK-1.png" alt="GivePower" />
              </a>
          </div>
          <div className="fw-nav-wrap" itemScope="itemScope" itemType="https://schema.org/SiteNavigationElement" role="navigation">
            <nav id="fw-menu-primary" className="fw-site-navigation primary-navigation">
                <ul id="menu-desktop-menu" className="fw-nav-menu">
                  <li id="menu-item-1465" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-1465"><a href="https://givepower.org/" hidefocus="true" style={{outline: 'none'}}><span>HOME</span></a></li>
                  <li id="menu-item-927" className="menu-item menu-item-type-post_type menu-item-object-page page_item page-item-881 menu-item-927"><a href="https://givepower.org/projects-2/" hidefocus="true" style={{outline: 'none'}}><span>PROJECTS</span></a></li>
                  <li id="menu-item-926" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-926"><a href="https://givepower.org/get-involved/" hidefocus="true" style={{outline: 'none'}}><span>PARTNER WITH US</span></a></li>
                  <li id="menu-item-1499" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1499"><a href="https://givepower.org/news/" hidefocus="true" style={{outline: 'none'}}><span>NEWS</span></a></li>
                  <li id="menu-item-3239" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3239"><a href="https://givepower.org/contact-us/" hidefocus="true" style={{outline: 'none'}}><span>CONTACT</span></a></li>
                  <li id="menu-item-924" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-924"><a href="https://givepower.org/our-team-2/" hidefocus="true" style={{outline: 'none'}}><span>OUR TEAM</span></a></li>
                  <li id="menu-item-829" className="menu-separator menu-item menu-item-type-custom menu-item-object-custom menu-item-829"><span>|</span></li>
                  <li id="menu-item-1411" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1411"><a href="https://donate.givepower.org/donate-now" hidefocus="true" style={{outline: 'none'}}><span><span className="donate-button" style={{color: '#FFD02D'}}>DONATE</span></span></a></li>
                </ul>
                <ul className="mobile-nav">
                  <li style={{ position: 'relative' }}>
                    <button className="sliiider-toggle mobile-nav-icon js--nav-icon">
                      <i className="ion-navicon-round" />
                    </button>
                  </li>
                </ul>
            </nav>
          </div>
        </div>
      </div>
	  </header>
    }
    </>
    );
}

export default Header;