import React from "react";
import { Paper, Grid, Box } from "@material-ui/core";

const WaveContainer = ({
  classes,
  isMobile,
  width,
  waveGraphicMobile,
  waveGraphic,
}) => {
  return (
    <Grid container className={classes.waveContainer}>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          className={classes.waveGraphicFeature}
          style={{
            backgroundImage: `url(${
              isMobile && width < 640 ? waveGraphicMobile : waveGraphic
            })`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={isMobile ? waveGraphicMobile : waveGraphic}
              alt="..."
            />
          }
          <div className={classes.overlay} />
          <Grid container>
            <Grid item md={12}>
              <Box className={classes.waveText}>
                Feeling the thirst you have now,{" "}
                <strong>
                  <i>how much would you be willing to pay</i>
                </strong>{" "}
                for a glass of fresh drinking water?
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default WaveContainer;
