import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "100% !important",
  },
  mainFeaturedPost: {
    position: "relative",
    color: "#ffffff",
    backgroundColor: "#252f3f", // '#00838f',
    backgroundSize: "cover",
    minHeight: "355px",
    marginBottom: theme.spacing(0),
    backgroundRepeat: "no-repeat",
    // height: '100%',
  },
  mainFeaturedPostContain: {
    position: "relative",
    color: "#ffffff",
    backgroundColor: "#252f3f", // '#00838f',
    backgroundSize: "cover",
    marginBottom: theme.spacing(0),
    backgroundRepeat: "no-repeat",
    height: "185px",
    [theme.breakpoints.down("sm")]: {
      height: "185px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "355px",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.2)",
    zIndex: 10,
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
    },
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
    color: "#ffffff",
    zIndex: 100,
    position: "relative",
    textDecoration: "underline",
  },
  logo: {
    display: "flex",
    flexDirection: "inherit",
    justifyContent: "start",
    position: "relative",
    top: "10px",
    left: "15px",
    zIndex: 20,
    // mixBlendMode: 'color-dodge',
  },
  logoImage: {
    maxWidth: "200px",
  },
  menu: {
    display: "flex",
    flexDirection: "inherit",
    justifyContent: "start",
    position: "absolute",
    top: "5px",
    right: "3%",
    zIndex: 200,
  },
  upperText: {
    color: "#ffffff",
    zIndex: 100,
    fontFamily: "futura-pt, sans-serif",
    fontWeight: 700,
    fontSize: "1.6rem !important",
    lineHeight: "25px",
    position: "relative",
    textAlign: "right",
  },
  whiteText: {
    color: "#ffffff",
    zIndex: 100,
    fontFamily: "futura-pt, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "1.6rem",
    lineHeight: "20px",
    position: "relative",
    textAlign: "left",
  },
  whiteTextMargin: {
    color: "#ffffff",
    zIndex: 100,
    fontFamily: "futura-pt, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "1.9rem",
    lineHeight: "35px !important",
    position: "relative",
    textAlign: "left",
    margin: "10px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem !important",
      lineHeight: "20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.9rem !important",
      lineHeight: "35px !important",
    },
  },
  middleTextMargin: {
    color: "#ffffff",
    zIndex: 100,
    fontFamily: "futura-pt, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.9rem",
    lineHeight: "35px !important",
    position: "relative",
    textAlign: "left",
    margin: "10px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem !important",
      lineHeight: "20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.9rem !important",
      lineHeight: "35px !important",
    },
  },
  lowerTextMargin: {
    color: "#ffffff",
    zIndex: 100,
    fontFamily: "futura-pt, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "25px !important",
    position: "relative",
    textAlign: "left",
    margin: "20px 0",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem !important",
      lineHeight: "16px !important",
      fontWeight: 300,
      width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem !important",
      lineHeight: "25px !important",
    },
  },
  description: {
    color: "#ffffff",
    zIndex: 100,
    position: "relative",
    fontSize: "1rem",
    lineHeight: "20px",
    paddingTop: "10px",
    textAlign: "right",
    // marginRight: theme.spacing(3)
  },
  signature: {
    display: "flex",
    fontSize: "1.5rem",
    alignItems: "flex-end",
  },
}));

export default function StarHero({ data }) {
  const classes = useStyles();
  const post = {
    image: data.imageMobile,
    imageClass: data.imageClass,
    backgroundColor: data.backgroundColor,
    text: data.text,
    text2: data.text2,
    text3: data.text3,
    text4: data.text4,
    textClass: data.textClassM,
    textClass2: data.textClassM2,
    textClass3: data.textClassM3,
    title: data.title,
    titleClass: data.titleClassM,
    textSpace: data.textSpace,
    description: data.descriptionM,
    linkText: data.linkText,
    signature: data.signature,
    columnLeft: data.columnLeft,
    columnRight: data.columnRight,
    contentAlign: data.align,
    font: data.font,
    menu: data.menu,
    top: data.topM,
  };

  function createMarkup(html) {
    return { __html: html };
  }

  return (
    <div className={classes.root}>
      <Paper
        className={classes[post.imageClass]}
        style={{ backgroundImage: `url(${post.image})` }}
      >
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: "none" }}
            src={post.image}
            alt={post.imageText}
          />
        }
        <div className={classes.overlay} />
        <Grid container>
          <Grid item xs={post.columnLeft} sm={post.columnLeft}>
            {post.contentAlign === "left" && (
              <div className={classes.mainFeaturedPostContent}>
                <Typography
                  variant="h3"
                  className={classes[post.textClass]}
                  style={{ textAlign: "left" }}
                  color="inherit"
                  gutterBottom
                >
                  <div dangerouslySetInnerHTML={createMarkup(post.text)} />
                </Typography>
                {post.text2 && (
                  <Typography
                    variant="h3"
                    className={classes[post.textClass2]}
                    style={{ textAlign: "left" }}
                    color="inherit"
                    gutterBottom
                  >
                    <div dangerouslySetInnerHTML={createMarkup(post.text2)} />
                  </Typography>
                )}
                {post.text3 && (
                  <Typography
                    variant="h3"
                    className={classes[post.textClass]}
                    style={{ textAlign: "left" }}
                    color="inherit"
                    gutterBottom
                  >
                    <div dangerouslySetInnerHTML={createMarkup(post.text3)} />
                  </Typography>
                )}
                {post.text4 && (
                  <Typography
                    variant="h3"
                    className={classes[post.textClass3]}
                    style={{ textAlign: "left" }}
                    color="inherit"
                    gutterBottom
                  >
                    <div dangerouslySetInnerHTML={createMarkup(post.text4)} />
                  </Typography>
                )}
                <Typography
                  variant="h3"
                  className={classes[post.titleClass]}
                  style={{ textAlign: "left" }}
                  color="inherit"
                  gutterBottom
                >
                  {post.title}
                </Typography>
                <Typography
                  variant="h5"
                  color="inherit"
                  className={classes.description}
                  style={{ textAlign: "left" }}
                  paragraph
                >
                  {post.description}
                </Typography>
                {post.linkText !== "" && (
                  <Link variant="subtitle1" className={classes.link} href="#">
                    {post.linkText}
                  </Link>
                )}
                {post.signature !== "" && (
                  <Typography
                    className={classes.signature}
                    component="h5"
                    color="inherit"
                  >
                    {post.signature}
                  </Typography>
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={post.columnLeft} sm={post.columnLeft}>
            {post.contentAlign === "right" && (
              <div
                className={classes.mainFeaturedPostContent}
                style={{ fontSize: post.font, top: post.top }}
              >
                <div className={classes[post.textClass]}>{post.text}</div>
                <div className={classes[post.titleClass]}>{post.title}</div>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.description}
                  paragraph
                >
                  {post.description}
                </Typography>
                {post.linkText !== "" && (
                  <Link variant="subtitle1" className={classes.link} href="#">
                    {post.linkText}
                  </Link>
                )}
                {post.signature !== "" && (
                  <Typography
                    className={classes.signature}
                    component="h6"
                    color="inherit"
                  >
                    {post.signature}
                  </Typography>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

StarHero.propTypes = {
  post: PropTypes.object,
};
