import React from "react";
import { Paper, Grid } from "@material-ui/core";

const WhoQuoteContainer = ({ classes }) => {
  return (
    <Grid container className={classes.whoQuoteContainer}>
      <Grid item xs={12} className={classes.whoQuoteBackground}>
        <Paper elevation={0} className={classes.paperCenterWho}>
          <p className={classes.paragraphMediumItalic}>
            “When water comes from improved and more accessible sources, people
            spend less time and effort physically collecting it, meaning they
            can be productive in other ways. This can also result in greater
            personal safety by reducing the need to make long or risky journeys
            to collect water. Better water sources also mean less expenditure on
            health, as people are less likely to fall ill and incur medical
            costs, and are better able to remain economically productive.”
          </p>
          <div className="author-large text-white">
            -World Health Organization
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default WhoQuoteContainer;
